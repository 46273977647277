import React from 'react';
import JSAlert from 'js-alert';
export default function (props){
  const [newmsg,setNewmsg] = React.useState("");
  const [msgs,setMsgs] = React.useState([]);
  const createNotification = props.createNotification;
  const changeMsgCount = props.changeMsgCount;
  React.useEffect(()=>{
    let o ={
      action : "fetchToDo",
      content : newmsg
    }
    fetch(props.url+"api.php?data", {
      method: 'POST',
      mode: 'cors',
      cache: 'default',
      credentials : 'include',
      body : JSON.stringify(o),
       })
     .then((response) => response.json())
     .then(
        (res) => {
            setMsgs(res);
        }).catch((error) => {
          if(error)JSAlert.alert(error,null,JSAlert.Icons.Failed)
        });
  },[])
  const add = () =>{
    let o ={
      action : "newToDo",
      content : newmsg
    }
    fetch(props.url+"api.php?data", {
      method: 'POST',
      mode: 'cors',
      cache: 'default',
      credentials : 'include',
      body : JSON.stringify(o),
       })
     .then((response) => response.json())
     .then(
        (res) => {
          createNotification(res.status,res.message);
          if(res.status==="success"){
            let list = [res.data,...msgs];
            setMsgs(list);
            changeMsgCount("plus");
          }
        }).catch((error) => {
          if(error)JSAlert.alert(error,null,JSAlert.Icons.Failed)
        });
  }
  const remove = (recnum)=>{
    let o ={
      action : "removeToDo",
      recnum: recnum
    }
    JSAlert.confirm("Delete message ?",null, JSAlert.Icons.Deleted).then(function(result) {
      if (!result)return
      fetch(props.url+"api.php?data", {
        method: 'POST',
        mode: 'cors',
        cache: 'default',
        credentials : 'include',
        body : JSON.stringify(o),
         })
       .then((response) => response.json())
       .then(
          (res) => {
            createNotification(res.status,res.message);
            let list = [...msgs];
            let index = list.findIndex((msg)=>{
              return msg.recnum===recnum;
            })
            list.splice(index,1);
            setMsgs(list);
            changeMsgCount("minus");
          }).catch((error) => {
            if(error)JSAlert.alert(error,null,JSAlert.Icons.Failed)
          });
      })
  }
  return (
    <div>
      <div className="form-group">
        <label className="form-label">New message</label>
        <textarea className="form-control" value={newmsg} onChange={(e)=>setNewmsg(e.target.value)}/>
      </div>
      <button className="btn btn-success" onClick={add}>Add</button>
      <div>
        <ul className="list-group mt-3">
          {msgs.map((msg)=>{
            return(
              <li className="list-group-item">
                <span className="text-muted small">{msg.author} on {msg.created}</span>
                <button className="btn btn-sm btn-danger pull-right" onClick={()=>remove(msg.recnum)}><i className="fa fa-trash"/></button>
                <div>
                  {msg.content}
                </div>
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}
