import React, { Component } from 'react';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import DatePicker from 'react-datepicker';
import ReactTable from "react-table";
import moment from 'moment';
import JSAlert from 'js-alert';
import './App.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-notifications/lib/notifications.css';
import 'react-table/react-table.css'
import './font-awesome/css/font-awesome.min.css'
import Login from './login';
import Dropzone from 'react-dropzone'
import ReactExport from "react-data-export";
import {DGNForm} from "./dgn";
import Quotes from './quotes';
import arrayParser from './arrayParser';
import ReactTooltip from 'react-tooltip';
import ListEl from './listElement';
import IdleJs from 'idle-js';
import {NotesDownload} from './pdf';
import {ClientModal,CarrierModal,NotifyModal,EmailModal} from './modals'
import {containers} from "./settings";
import AdditionalCosts from "./Shipments/additional_costs.js";
import Messages from "./Messages";
import countries from "./countries.json";
import Select from 'react-select';
//import { unregister } from './registerServiceWorker';
//unregister();
const url = "https://rcm.freight4ward.com/";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const initialShipment = {
	region : 'Import',
	billing_party:'',
	dimensions : [],
	comments : [],
	client_reference : '',
	nl_ref : '',
	//quotation_number:'',
	carrier_name:'',
	client_name:'',
	goods_description:'',
	pickup_name:'',
	delivery_name:'',
	new_comment_text : '',
	new_comment_date : '',
	pickup_street:'',
	pickup_city:'',
	pickup_country:'',
	pickup_zip:'',
	delivery_street:'',
	delivery_city:'',
	delivery_country:'',
	delivery_zip:'',
	special_instructions:'',
	status:'Created',
	danger_goods:'',
	total_weight : 0,
	pieces : 0,
	files : [],
	filesnames: [],
	notice_emails : '',
	pickup_contact:'',
	delivery_contact:'',
	delivery_opening_hours:'',
	pickup_opening_hours:'',
	collection_reference : '',
	delivery_reference : '',
	hs_attached : false,
	sor_updated : false,

	price:'0.00',
	sales_rate:'0.00',
	carrier_extra_costs:'0.00',
	customer_extra_costs:'0.00',
	//dropoff address area
	dropoff_name:'',
	dropoff_street:'',
	dropoff_city:'',
	dropoff_country:'',
	dropoff_zip:'',
	dropoff_contact:'',
	dropoff_opening_hours:'',
	dropoff_reference:'',
	//customs address area
	customs_name:'',
	customs_street:'',
	customs_city:'',
	customs_country:'',
	customs_zip:'',
	customs_contact:'',
	customs_opening_hours:'',

	temperature_required : false,
	temperature:'',
	carrier_emails:'',
	carrier_comments:'',
	client_comments:'',
	client_name:'',
	kind:'',
	customs_date:'',
	dropoff_date:'',
	posit_date:'',
	pickup_date:'',
	eta:'',
	vessel_name:'',
	notify_carrier:false,
	notify_client:false,
	customs_clearance : false,
	customs_documents:'',
	customs_costs:'0.00',
	customs_broker:'',
	customer_additional_costs : [],
	carrier_additional_costs : []
};
const tableFiltersList ={
	quotation_number:"",
	customer_po:"",
	pickup_name:"",
	delivery_name:"",
	delivery_zip:"",
	pickup_zip:""
}

 class App extends Component {
  constructor(props) {
    super(props);
    this.switchTab = this.switchTab.bind(this);
    this.switchTabDva = this.switchTabDva.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.updateTable = this.updateTable.bind(this);
    this.openDetail = this.openDetail.bind(this);
    this.performSearch = this.performSearch.bind(this);
    this.updateRecord = this.updateRecord.bind(this);
    this.createRecord = this.createRecord.bind(this);
    this.addDimensionsRow = this.addDimensionsRow.bind(this);
    this.delDimensionsRow = this.delDimensionsRow.bind(this);
    this.shipmentHandleChange = this.shipmentHandleChange.bind(this);
    this.searchFormHandleChange = this.searchFormHandleChange.bind(this);
    this.updateDimensionRow = this.updateDimensionRow.bind(this);
    this.saveRecord = this.saveRecord.bind(this);
    this.showMenu=this.showMenu.bind(this);
    this.loginSuccess = this.loginSuccess.bind(this);
    this.signout = this.signout.bind(this);
    this.printShipment=this.printShipment.bind(this);
		this.handleFileUpload=this.handleFileUpload.bind(this);
		this.downloadFile = this.downloadFile.bind(this);
		this.resetSearch = this.resetSearch.bind(this);
		this.deleteAttachment=this.deleteAttachment.bind(this);
		this.deleteRecord=this.deleteRecord.bind(this);
		this.saveSettings = this.saveSettings.bind(this);
		this.addToAddressBook=this.addToAddressBook.bind(this);
		this.openAddressBook = this.openAddressBook.bind(this);
		this.editAddress = this.editAddress.bind(this);
		this.insertAddress = this.insertAddress.bind(this);
		this.deleteAddress = this.deleteAddress.bind(this);
		this.hideDGNForm = this.hideDGNForm.bind(this);
		this.getSelectedTableRows = this.getSelectedTableRows.bind(this);
		this.tableNeedsRefresh = false;
		this.insertElement = "";
		this.emailsAddr = {};
		this.quoteFormRef = React.createRef();
		this.initialsearchForm = {
			client_reference : '',
			nl_ref: '',
			pickup_name:'',
			delivery_name:'',
			loaded_from:'',
			loaded_to:'',
			created_from :'',
			created_to:'',
			status: '',
			//quotation_number:'',
			eta_from:'',
			eta_to:'',
			region : '',
			sor_updated:'ALL',
			currentMonth : moment().month()+1,
			currentYear : moment().year()
		};
    this.state = {
			mainContainer : "col-12",
			detailContainer : "d-none",
			settingsContainer : "d-none",
			AddressbookContainer : "d-none",
			quotesListContainer : "d-none",
			date : null,
			tableData : [],
			addressBookData : [],
			addressBookDataVisible :[],
			shipment : {
				dimensions : [],
				comments : [],
				files:[],
				filesnames : [],
				customer_additional_costs : [],
				carrier_additional_costs : []
			},
			changed_items : [],
			searchForm : {...this.initialsearchForm},
			isNewRecord : true,
			isEditRecord : false,
			shipment_selected_packing : 'Pallet',
			user : { name : "", role : "" },
			userLoged:false,
			userSignout:false,
			menu_collapse : "collapse",
			pdf_replace_delivery_address : false,
			trailersModuleLoaded : false,
			visibleTableData : [],
			selectedTableRows :[],
			multiselect : false,
			tableFilterInputs : tableFiltersList,
			expandedRows:[],
			addressbookFilterInputs:[],
			findTrailer : null,
			total_gm_percent : "N/A",
			total_gm_eur : "N/A",
			addressbookFilterTypeOptions : [],
			displayClientModal:false,
			displayCarrierModal:false,
			modalFileSelection : [],
			displayUpdateModal : false,
			displayEmailModal : false,
			emailModalTo : null,
			btnDropDownShow:false,
			msgCount : 0
    }
  }
	componentDidMount(){
		this.idle = new IdleJs({
		  idle: 360000, // idle time in ms
		  events: ['mousemove', 'keydown', 'mousedown', 'touchstart'], // events that will trigger the idle resetter
	//	  onIdle: function () { }, // callback function to be executed after idle time
		  onActive: ()=> { if(this.state.mainContainer==="col-12"&&this.state.userLoged===true)this.performSearch() }, // callback function to be executed after back form idleness
	//	  onHide: function () { }, // callback function to be executed when window become hidden
		//  onShow: ()=> { if(this.state.mainContainer==="col-12"&&this.state.userLoged===true)this.performSearch() }, // callback function to be executed when window become visible
		  keepTracking: true, // set it to false if you want to be notified only on the first idleness change
		  startAtIdle: false // set it to true if you want to start in the idle state
		});
		this.idle.start();

	}
  loginSuccess = (val) => {
    this.setState({
			user : { name : val.name , role : val.role, username:val.username, canCreateRecord : val.canCreateRecord === "t"? true : false },
			userLoged : true,
			userSignout : false,
    })
    this.emailsAddr = JSON.parse(val.config);
    this.performSearch();
    if(window.location.hash.indexOf("#")!==-1){
    	if(Number.isInteger(Number(window.location.hash.substr(1)))){
			this.openDetail(window.location.hash.substr(1));
				window.location.hash="";
		}
    }
		this.getMsgCount()
		setInterval(()=>{
			this.getMsgCount()
		},600000)
	}
	getMsgCount = ()=>{
		fetch(url+"api.php?data" , {
			cache: 'default',
			credentials : 'include',
			method : 'POST',
			body : JSON.stringify({action:"countToDo"}),
		 }).then((response) => response.json())
			 .then(
					 (res) => {
						this.setState({msgCount : res});
					}
		 ).catch((error) => {
				if(error)JSAlert.alert(error,null,JSAlert.Icons.Failed)
		 });
	}
	changeMsgCount = (how)=>{
		let count = this.state.msgCount;
		if(how==="minus")count--;
		if(how==="plus")count++;
		this.setState({msgCount : count});
	}
	signout(){
		fetch(url+"api.php?action=signout" , {
			method: 'GET',
			credentials: 'include'
	  		 }).then(
	  		 	this.setState({
						user : {},
						userSignout : true,
						userLoged : false
			   	})
		).catch((error) => {
         if(error)JSAlert.alert(error,null,JSAlert.Icons.Failed)
      });
	}

addToAddressBook(e,vals){
	var self = this;
	if(e.startsWith("quote")){
		e = e.slice(6)
	}
	var a = e;
	var b = e;
	if(vals===undefined){
		vals = this.state.shipment;
	}

	if (a==="posit")b="delivery";
	var t = "<table><tr><td>Type:</td><th>"+e+"</th></tr><tr><td>Name:</td><th>"+vals[b+"_name"]+"</th></tr>"+
	"<tr><td>Street:</td><th>"+vals[b+"_street"]+"</th></tr>"+
	"<tr><td>City:</td><th>"+vals[b+"_city"]+"</th></tr>"+
	"<tr><td>Country:</td><th>"+vals[b+"_country"]+"</th></tr>"+
	"<tr><td>Post code:</td><th>"+vals[b+"_zip"]+"</th></tr>"+
	"<tr><td>Contact:</td><th>"+vals[b+"_contact"]+"</th></tr>"+
	"<tr><td>Opening hours:</td><th>"+vals[b+"_opening_hours"]+"</th></tr>"+
	"</table>";
	JSAlert.confirm("Are you sure you want to add following to address book ?"+t).then(function (result) {
	if (!result)return;
	  var o = {
	    type:e,
	    action : "addtoaddressbook",
	    name : vals[b+"_name"],
	    street : vals[b+"_street"],
	    city: vals[b+"_city"],
	    country : vals[b+"_country"],
	    zip : vals[b+"_zip"],
	    contact : vals[b+"_contact"],
	    opening_hours : vals[b+"_opening_hours"],
	  }
	 fetch(url+"api.php?data", {
	 method: 'POST',
    mode: 'cors',
    cache: 'default',
	 credentials : 'include',
		body : JSON.stringify(o),
  		 })
		 .then((response) => response.json())
		 .then(
        (res) => {
        	self.createNotification(res.status,res.message)
				if (res.status==="success") {


				}
        }).catch((error) => {
         	if(error)JSAlert.alert(error,null,JSAlert.Icons.Failed)
        });
	})
}
editAddress(props) {
	var self = this;
var t = "<form id='editaddressForm' style='min-width:500px;'><input type='hidden' name='recnum' value='"+props.recnum+"'><table style='width:100%' class='table-striped'><tr><tr><td>Name</td><td><input class='form-control' name='name' value='"+props.name+"'</td></tr>";
t+="<tr><td>Street</td><td><input name='street' class='form-control' value='"+props.street+"'</td></tr>";
t+="<tr><td>City</td><td><input name='city' class='form-control' value='"+props.city+"'</td></tr>";
t+="<tr><td>Country</td><td><input name='country' class='form-control' value='"+props.country+"'</td></tr>";
t+="<tr><td>Post Code</td><td><input name='zip' class='form-control' value='"+props.zip+"'</td></tr>";
t+="<tr><td>Contact person</td><td><input name='contact' class='form-control' value='"+props.contact+"'</td></tr>";
t+="<tr><td>Opening hours</td><td><input name='opening_hours' class='form-control' value='"+props.opening_hours+"'</td></tr>";
t+="<tr><td>Customs clearance</td><td><div id='editaddressFormRadios'>"+props.customs_clearance;
t+="<label><input type='radio' name='customs_clearance' value='' "+(props.customs_clearance ==='' ? "checked":"")+"> Not specified</label>";
t+="<label><input type='radio' name='customs_clearance' value='Shipper To Arrange/Forward EX-A' "+(props.customs_clearance ==='Shipper To Arrange/Forward EX-A' ? "checked":"")+"> Shipper To Arrange/Forward EX-A</label>";
t+="<label><input type='radio' name='customs_clearance' value='MW to arrange customs for shipper' "+(props.customs_clearance ==='MW to arrange customs for shipper' ? "checked":"")+"> MW to arrange customs for shipper</label>";
t+="<label><input type='radio' name='customs_clearance' value='Po\'s To Be Cleared On Trailer' "+(props.customs_clearance ==='Po\'s To Be Cleared On Trailer' ? "checked":"")+"> Po\'s To Be Cleared On Trailer</label>";
t+="</div></td></tr></form></table>";
var alert = new JSAlert(t,"Edit Address");
alert.addButton("Cancel").then(function() {
    return;
});
alert.addButton("Save").then(function() {
    var f = document.getElementById("editaddressForm");
		var o = {
		action : "editaddressbook",
		recnum : f.recnum.value,
		name : f.name.value,
		street : f.street.value,
		city: f.city.value,
		country : f.country.value,
		zip : f.zip.value,
		contact : f.contact.value,
		opening_hours : f.opening_hours.value,
		customs_clearance : f.customs_clearance.value
		}

	 fetch(url+"api.php?data", {
	 method: 'POST',
    mode: 'cors',
    cache: 'default',
	 credentials : 'include',
		body : JSON.stringify(o),
  		 })
		 .then((response) => response.json())
		 .then(
        (res) => {
        	self.createNotification(res.status,res.message)
				if (res.status==="success") {
					var data = self.state.addressBookData;
					data.forEach(function (e,i) {
						if (e.recnum===o.recnum) {
							data[i]=o;
						}
					})
					self.setState({addressBookData :data,addressBookDataVisible:data,addressbookFilterInputs:[]});
				}
        }).catch((error) => {
         	if(error)JSAlert.alert(error,null,JSAlert.Icons.Failed)
        });
});
alert.show();
}
openAddressBook(e){
	this.insertElement = e;
	if(e.startsWith("quote")){
		e = e.slice(6)
	}
	this.setState({ AddressbookContainer : "col-12", detailContainer : "d-none" });
		var al = JSAlert.loader("<i class='fa fa-spinner fa-pulse fa-3x fa-fw'></i><br>Please wait...")
		fetch(url+"api.php?addressbook", {
		method: 'GET',
		credentials: 'include',
  		 })
		 .then((response) => response.json())
		 .then(
        (res) => {
        		al.dismiss();
						let options = res.map((r)=>{ return r.type })
						options = options.filter(function(item, pos) {
						    return options.indexOf(item) == pos;
						})
						options.unshift("All")
						this.setState({ addressBookData : res,addressBookDataVisible:res,addressbookFilterInputs:{name:""},addressbookFilterTypeOptions:options },()=>{
							this.addressbookFilter({target : {name:"type",value:e}})
						} )
        }).catch((error) => {
         	if(error)JSAlert.alert(error,null,JSAlert.Icons.Failed)
        });
}
insertAddress(e){
	/*
	if (this.state.isNewRecord!==true){
		this.createNotification("info","Changes to existing records are not allowed..");
		return
	}
	*/
	var a = this.insertElement;
	if(a.startsWith("quote")){
		this.setState({ AddressbookContainer : "d-none",quotesListContainer:"col-12" });
		return this.quoteFormRef.current.insertAddress(e,a);
	}
	if (this.insertElement==="posit")a="delivery";
	var o = {};
	o[a+"_name"]=e.name;
	o[a+"_street"]=e.street;
	o[a+"_city"]=e.city;
	o[a+"_country"]=e.country;
	o[a+"_zip"]=e.zip;
	o[a+"_contact"]=e.contact;
	o[a+"_opening_hours"]=e.opening_hours;
	if(a==="pickup"){
		o.customs_clearance = e.customs_clearance;
	}
	this.setState({ shipment : { ...this.state.shipment,...o},detailContainer : "col-12",AddressbookContainer : "d-none" });
	window.setTimeout(()=>{ window.document.getElementById(this.insertElement).scrollIntoView() },10);
}
deleteAddress(e){
	var self = this;
JSAlert.confirm("Are you sure you want to delete this row from address book?",null, JSAlert.Icons.Deleted).then(function(result) {
    if (!result)
        return;
		fetch(url+"api.php?deleteaddress="+e, {
		method: 'GET',
		credentials: 'include',
  		 })
		 .then((response) => response.json())
		 .then(
        (res) => {
        	self.createNotification(res.status,res.message)
        }).catch((error) => {
         	if(error)JSAlert.alert(error,null,JSAlert.Icons.Failed)
        });
    })
}
downloadFile(num){
	window.open(url+"api.php?downloadRecnum="+num+"&stamp"+new Date().toUTCString() );
}
deleteAttachment(num,clientside){
	var self= this;
	if (clientside===true) {
		var a = [];
		Object.assign(a,this.state.shipment.files);
		a = a.filter(e=> e.name!==num);
		self.setState({ shipment : { ...self.state.shipment, files : a }});
		return;
	}

JSAlert.confirm("Are you sure you want to delete this file?<br>This action has an immediate effect, no way to roll back...",null, JSAlert.Icons.Deleted).then(function(result) {
    if (!result)
        return;
      var o = {
			action:'deletefile',
			file:num,
			fromshipment:self.state.shipment.recnum
      }
		fetch(url+"api.php?data", {
	 method: 'POST',
    mode: 'cors',
    cache: 'default',
	 credentials : 'include',
		body : JSON.stringify(o),
  		 })
		 .then((response) => response.json())
		 .then(
        (res) => {
        	self.createNotification(res.status,res.message)
				if (res.status==="success") {
					var files = self.state.shipment.filesnames;
					files = files.filter(function(item) {
   				 return item[0] !== num
					})
					self.setState({ shipment : {...self.state.shipment,filesnames:files } })
				}
        }).catch((error) => {
         	if(error)JSAlert.alert(error,null,JSAlert.Icons.Failed)
        });
	})
}
   createNotification(type,message) {

      switch (type) {
        case 'info':
          NotificationManager.info(message);
          break;
        case 'success':
          NotificationManager.success(message);
          break;
        case 'warning':
          NotificationManager.warning(message);
          break;
        case 'error':
          NotificationManager.error(message, '', 0);
          break;
      }

  };
  printShipment(t){

	if(t==="p")window.open(url+"api.php?printRecnum="+this.state.shipment.recnum+"&stamp"+new Date().toUTCString());
	if(t==="c")window.open(url+"api.php?printDelivery="+this.state.shipment.recnum+"&replace_delivery_address="+this.state.pdf_replace_delivery_address+"&stamp"+new Date().toUTCString());
//	this.setState({ output_ref : "https://ieb-rcm.mauriceward.com/api.php?printRecnum="+this.state.shipment.recnum+"&stamp"+new Date().toUTCString() });
  }
	validateEmail(mail){
	 if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
	  {
	    return (true)
	  }
	    return (false)
	}
	sendShipment = ()=>{
		if(this.state.changed_items.length>0){
			let res = JSAlert.alert('There are some unsaved changes in the form. Save them first to prevent any mistakes in sent out data.');
			return;
		}
		let emails = this.state.shipment.carrier_emails.split(",");
		for(let i=0;i<emails.length;i++){
			if(!this.validateEmail(emails[i])){
				JSAlert.alert('"'+emails[i]+ '" is not valid email address. Enter valid addresses separated by commas and repeat action ');
				return;
			}
		}
		this.setState({
			displayCarrierModal:true,
		})
	}
	sendClientBooking = async()=>{
		if(this.state.changed_items.length>0){
			let res = JSAlert.alert('There are some unsaved changes in the form. Save them first to prevent any mistakes in sent out data.');
			return;
		}
		let emails = this.state.shipment.carrier_emails.split(",");
		for(let i=0;i<emails.length;i++){
			if(!this.validateEmail(emails[i])){
				JSAlert.alert('"'+emails[i]+ '" is not valid email address. Enter valid addresses separated by commas and repeat action.');
				return;
			}
		}
		this.setState({
			displayClientModal:true,
		})
		/*
		JSAlert.confirm("",null).then(function(result) {
				if (!result)
						return;
					var o = {
						action:'send_booking',
						recnum : self.state.shipment.recnum,
						mail : self.state.shipment.carrier_emails
					}
				fetch(url+"api.php?data", {
					method: 'POST',
					mode: 'cors',
					cache: 'default',
					credentials : 'include',
					body : JSON.stringify(o),
					 })
				 .then((response) => response.json())
				 .then(
						(res) => {
							self.createNotification(res.status,res.message)
						}).catch((error) => {
							if(error)JSAlert.alert(error,null,JSAlert.Icons.Failed)
						});
			})
			*/
	}
	switchTab(what) {
		switch(what){
				case "new" :
					this.setState({
						shipment : {...initialShipment},
						isNewRecord : true,
						isEditRecord : false,
						changed_items : [],
					mainContainer : "d-none",
					detailContainer : "col-12",
					settingsContainer :"d-none",
					quotesListContainer : "d-none"
					})
					window.scrollTo(0,56)
				break;
				case "settings" :
					this.setState({
						mainContainer : "d-none",
						detailContainer : "d-none",
						settingsContainer : "col-12",
						AddressbookContainer : 'd-none',
						quotesListContainer : "d-none"
					})
				break;
				case "quotesList" :
					this.setState({
						mainContainer : "d-none",
						detailContainer : "d-none",
						settingsContainer : "d-none",
						quotesListContainer : "col-12",
						AddressbookContainer : 'd-none',
						trailersModuleLoaded : true
					})

				break;
				default :
					this.setState({
						isNewRecord : false,
						isEditRecord : true,
						mainContainer : "d-none",
						detailContainer : "col-12",
						settingsContainer :"d-none",
						AddressbookContainer : 'd-none',
						quotesListContainer : "d-none"
					});
					window.scrollTo(0,56);
				break;
			}
	}
	switchTabDva(e) {
		this.performSearch();
		if (this.tableNeedsRefresh ===true){
			this.performSearch();
			this.tableNeedsRefresh = false;
		}
		this.setState({
			mainContainer : "col-12",
			detailContainer : "d-none",
			detailText : "",
			settingsContainer :"d-none",
			quotesListContainer : "d-none"
		})
		window.scrollTo(0,56);
	}
	handleChange(date){
		this.setState({
			date:date
		})
	}
	saveSettings(e){
		e.preventDefault();
		var data = new FormData(e.target);
		var o = {
			"action":"savesettings",
			"config": {
				"EU Collection" : data.get("EU Collection"),
				"T1 / Article 23" : data.get("T1 / Article 23"),
				"Synergy" : data.get("Synergy"),
				"Domestic Collection" : data.get("Domestic Collection"),
				"Fragrance Direct" : data.get("Fragrance Direct")
			}
		}
	fetch(url+"api.php?data" , {
	 method: 'POST',
    mode: 'cors',
    cache: 'default',
	 credentials : 'include',

		body : JSON.stringify(o),
  		 })
		 .then((response) => response.json())
		 .then(
        (res) => {
				this.createNotification(res.status,res.message);
        }).catch((error) => {
         	if(error)JSAlert.alert(error,null,JSAlert.Icons.Failed)
        });
	}
shipmentHandleChange = (e)=>{
		var value = e.target.value;
		var name = e.target.name;
		var shipment = {...this.state.shipment};
		if (e.target.type==="checkbox") {
			value = e.target.checked;
		}
		var changeditems = this.state.changed_items;
		switch(name) {
			case "client_comments":
				shipment[name] = value;
				shipment.notify_client=true;
				break;
			case "carrier_comments":
				shipment[name] = value;
				shipment.notify_carrier=true;
				break;
			case "sales_rate":
				shipment[name] = value.replace(/[^0-9\.]+/g,"");
				shipment.notify_client=true;
			break;
			case "customer_extra_costs":
				shipment[name] = value.toFixed(2);
				shipment.notify_client=true;
				break;
			case "price":
				shipment[name] = value.replace(/[^0-9\.]+/g,"");
				shipment.notify_carrier=true;
			break;
			case "carrier_extra_costs":
				shipment[name] = value.toFixed(2);
				shipment.notify_carrier=true;
			break;
			case "customs_costs":
				shipment[name] = value.replace(/[^0-9\.]+/g,"");
			break;
			case "delivery_name":
			case "delivery_street":
			case "delivery_city":
			case "delivery_zip":
			case "delivery_country":
			case "delivery_contact":
			case "delivery_opening_hours":
			case "pickup_name":
			case "pickup_street":
			case "pickup_city":
			case "pickup_zip":
			case "pickup_country":
			case "pickup_contact":
			case "pickup_opening_hours":
			case "dropoff_name":
			case "dropoff_street":
			case "dropoff_city":
			case "dropoff_zip":
			case "dropoff_country":
			case "dropoff_contact":
			case "dropoff_opening_hours":
			case "customs_name":
			case "customs_street":
			case "customs_city":
			case "customs_zip":
			case "customs_country":
			case "customs_contact":
			case "customs_opening_hours":
			case "pickup_date":
			case "posit_date":
			case "dropoff_date":
			case "customs_date":
			case "collection_reference":
			case "delivery_reference":
			case "dropoff_reference":
			case "goods_description":
			case "special_instructions":
			case "danger_goods":
			case "temperature_required":
			case "temperature":
			case "vessel_name":
			case "eta":
				shipment[name] = value;
				shipment.notify_client=true;
				shipment.notify_carrier=true;
			break;
			case "region":
				shipment.notice_emails = this.emailsAddr[value];
			default :
				shipment[name]=value;
				break
		}
		this.setState( { shipment : shipment } );
		if(changeditems.indexOf(name) < 0){
			changeditems.push(name)
			this.setState( { changed_items : changeditems } )
		}

	}
searchFormHandleChange(e){
		let el = e.target;
		var value = e.target.type === 'checkbox' ? e.target.checked : e.target.value
		var name = e.target.name;
		var searchForm = {...this.state.searchForm};
		if (name==="created_to"|| name==="loaded_to"||name==="eta_to") {
			value = moment(value).hours(23).minutes(59);
		}
		if (name==="status"&&value==="Off loaded")searchForm["include_off"]=true;
		searchForm[name]=value;
		this.setState( { searchForm : searchForm },()=>{
			if(el.name==="currentMonth"||el.name==="currentYear"){
				this.performSearch()
			}
		} )

	}
resetSearch(){
	this.setState({ searchForm : {...this.initialsearchForm} })
}

 handleFileUpload( files ) {
	var self = this;
//	files = files.target.files;
	var count = files.length;
    if (files.length>0) {
    	var a = [];
    	Object.assign( a,this.state.shipment.files);
    	for(var i=0;i<count;i++){
         // create reader
        var reader = new FileReader();
        reader.readAsDataURL(files[i]);
        reader.onload = (function(file) {
        var filename = file.name;
        var mimetype = file.type;
        var ext = filename.split(".").pop();
        return async function (e) {
        var contents = e.target.result.split("base64,").pop();
					a.push({name:filename, mimetype: mimetype, source: contents, ext: ext });
					count--;
			if (count===0) {
				self.setState({ shipment : { ...self.state.shipment, files : a }});
				self.createNotification("info","Files are ready to upload" );
			}
        }})(files[i]);
    		}
    }


}
	updateTable(recnum){
		var tableData = this.state.tableData;
		var index = tableData.findIndex(e => e.recnum === recnum);
		tableData[index]=this.state.shipment;
		this.setState({tableData : tableData })
	}
	updateRecord(skip,vals){
		var data = {...this.state.shipment};
		data.action = "update";
		data.changeditems = this.state.changed_items;
		if(data.notify_client===true||data.notify_carrier===true){
			if(skip===true){
				data.notify_client = vals.notify_client;
				data.notify_carrier = vals.notify_carrier;
				this.saveRecord(data);
			}else {
				this.setState({
					displayUpdateModal:true
				})
			}
		}else {
			this.saveRecord(data);
		}
	}
	copyRecord(){
		var data = {...this.state.shipment};
		data.changeditems = [];
		data.files = [];
		data.filesnames = [];
		delete data.recnum;
		data.pickup_date = null;
		data.posit_date= null;
		data.customs_date= null;
		data.dropoff_date= null;
		data.eta= null;
		data.status="Created";
		data.created = null;
		this.setState({ shipment : data,isNewRecord : true, isEditRecord : false } )
	}
	createRecord(){
		var data = this.state.shipment;
		this.tableNeedsRefresh = true;
		data.action = "insert";
		var requiredFields = [
			//{name:"quotation_number",title : "Quotation number"},
			{name:"posit_date",title:"Posit date"},
			{name:"nl_ref",title : "F4W reference"},
			{name:"client_reference",title:"Client reference"},
			{name:"sales_rate",title:"Sales reference"},
			{name:"notice_emails",title: "Client email"},
			{name:"carrier_emails",title : "Carrier emails"},
			{name:"price",title:"Buying rate"},
			{name:"danger_goods", title:"DG/Non DG"}
		];
		var a = true;
		requiredFields.forEach(e=>{
			if (data[e.name]===""||data[e.name]===0){
				this.createNotification('warning',e.title+ " field is required");
				a=false;
			}
		})
		if(data.dimensions.length===0){
			//this.createNotification('warning',"Please enter cargo dimensions");
			//a = false;
		}
		if(a)this.saveRecord(data)
	}
	saveRecord(data){
		console.log(data)
		var myHeaders = new Headers();
		var self = this;
myHeaders.append('Content-Type', 'application/json');

	fetch(url+"api.php?data" , {
	 method: 'POST',
    headers: myHeaders,
    mode: 'cors',
    cache: 'default',
	 credentials : 'include',

		body : JSON.stringify(data),
  		 })
		 .then((response) => response.json())
		 .then(
        (res) => {
				this.createNotification(res.status,res.message);
				if (res.status==="success") {
					this.setState({isNewRecord:false,isEditRecord : true,changed_items:[] });
					this.updateTable(res.recnum);
					this.switchTabDva();
				}
        		if (res.status==="error"){
        			if (res.message==="Please login") {
        				JSAlert.alert("Session closed, please sign in again",null,JSAlert.Icons.Failed).then(()=>
        				 self.setState({
							user : {},
							userSignout : true,
							userLoged : false
				   	})
				   	)
        			}
        			else JSAlert.alert(res.message,null,JSAlert.Icons.Failed);
        			return;
        		}
        }).catch((error) => {
         	if(error)JSAlert.alert(error,null,JSAlert.Icons.Failed)
        });
	}
	deleteRecord(num){
		var self = this;
		JSAlert.confirm("Are you sure you want to delete this record and all attached files ?<br>This action has an immediate effect, no way to roll back...",null, JSAlert.Icons.Deleted).then(function(result) {
    if (!result)
        return;
      var o = {
			action:'deleterecord',
			shipment: num
      }
	fetch(url+"api.php?data" , {
	 method: 'POST',
    mode: 'cors',
    cache: 'default',
	 credentials : 'include',

		body : JSON.stringify(o),
  		 })
		 .then((response) => response.json())
		 .then(
        (res) => {
				self.createNotification(res.status,res.message);
				if (res.status==="success") {
					self.tableNeedsRefresh=true;
					self.switchTabDva();
				}
        }).catch((error) => {
         	if(error)JSAlert.alert(error,null,JSAlert.Icons.Failed)
        });
     })
	}
	addDimensionsRow(){
		var shipment = {...this.state.shipment};
		if(this.state.user.role !== "admin"&&this.state.isNewRecord===false&&(shipment.created_by!==this.state.user.username)){
			this.createNotification("info","You are not allowed to change this field.");
			return;
		}
		if(this.state.shipment_selected_packing==="Container"){
			this.setState( prevState =>({
				shipment : {
					...prevState.shipment,
					dimensions: [...prevState.shipment.dimensions,{
						rowId : prevState.shipment.dimensions.length,packing : this.state.shipment_selected_packing,
						length:'20"',
						number:'',
						tare : containers.find((r)=>{return r.type==='20"'}).weight,
						seanumber:'',
						carrier:'',
						weight:''
					} ],
					pieces : Number(prevState.shipment.pieces)+1,
					total_weight : Number(prevState.shipment.total_weight)+Number(containers.find((r)=>{return r.type==='20"'}).weight)
				}
			}))
		}else {
		this.setState( prevState =>({
			shipment : {
				...prevState.shipment,
				dimensions: [...prevState.shipment.dimensions,{ rowId : prevState.shipment.dimensions.length,packing : this.state.shipment_selected_packing,width:'',height:'',pieces:'',weight:'' } ]
			}
		}))
	}
	}
	delDimensionsRow(rowId){
		var shipment = {...this.state.shipment};
		if(this.state.user.role !== "admin"&&this.state.isNewRecord===false&&(shipment.created_by!==this.state.user.username)){
			this.createNotification("info","You are not allowed to change this field.");
			return;
		}
		var counter = 0;
		var rows = this.state.shipment.dimensions.filter((e)=>{
			if(e.rowId !== rowId){
				e.rowId = counter;
				counter++;
				return e;
			}
		})
		var total_weight = 0;
		var pieces = 0;
		rows.forEach((row)=>{

			if(row.packing==="Container"){
				pieces++;
				total_weight+= Number(row.weight) + Number(row.tare);
			}else {
				total_weight+= Number(row.weight) * Number(row.pieces);
				pieces += Number(row.pieces);
			}
		});
		this.setState( prevState =>({
			shipment : {
				...prevState.shipment,
				dimensions: rows,
				total_weight : total_weight,
				pieces:pieces
			}
		}) )
	}
	showMenu(){
		var collapse ="collapse";
		if (this.state.menu_collapse==="collapse") {
			collapse = "";
		}
		this.setState({menu_collapse : collapse })
	}
	updateDimensionRow(e,rowId){
		if(this.state.user.role !== "admin"&&this.state.isNewRecord===false){
			this.createNotification("info","You are not allowed to change this field.");
			return;
		}
		var total_weight = 0;
		var pieces = 0;
		var rows = this.state.shipment.dimensions.filter((row)=>{
			if(row.rowId === rowId){
				row[e.target.name]= e.target.value;
				if(row.packing==="Container"){
					pieces++;
					if(e.target.name==="length"){
						let tw = containers.find((r)=>{ return r.type===row.length});
						row.tare = tw!==undefined ? tw.weight :0;
					}
					total_weight+= Number(row.weight)+Number(row.tare);
				}else {
					total_weight+= Number(row.weight) * Number(row.pieces);
					pieces += Number(row.pieces);
				}
				return row;
			}
			else {
				if(row.packing==="Container"){
					pieces++;
					total_weight+= Number(row.weight)+Number(row.tare);
				}else {
					total_weight+=Number(row.weight) * Number(row.pieces);
					pieces += Number(row.pieces);
				}
				return row;
			}

		})
		let changeditems = this.state.changed_items;
		if(changeditems.indexOf("dimensions") < 0){
			changeditems.push("dimensions");
		}
		this.setState( prevState =>({
			shipment : {
				...prevState.shipment,
				dimensions: rows,
				total_weight : total_weight,
				pieces:pieces,
				notify_client:true,
				notify_carrier:true
			},
			changed_items : changeditems
		}) )
	}
	openDetail(id){
		var self=this;
		fetch(url+"api.php?recnum="+id, {
		method: 'GET',
		credentials: 'include'
  		 })
		 .then((response) => response.json())
		 .then(
        (res) => {
        		if (res.status==="error"){
        			if (res.message==="Please login") {
        				JSAlert.alert("Session closed, please sign in again",null,JSAlert.Icons.Failed).then(()=>
        				 self.setState({
							user : {},
							userSignout : true,
							userLoged : false
				   	})
				   	)
        			}
        			else JSAlert.alert(res.message,null,JSAlert.Icons.Failed);
        			return;
        		}
        		var shipment = res;
        		if (shipment===false) {
        			JSAlert.alert("Unable to open shipment, record doesn't exist.",null,JSAlert.Icons.Failed)
        			return false;
        		}
        		//osetreni boolean hodnot
        		shipment.hs_attached = shipment.hs_attached === "t" ? true : false;
        		//shipment.customs_clearance= shipment.customs_clearance === "t" ? true : false;
        		shipment.created = moment(shipment.created);
        		if(shipment.dimensions===null)shipment.dimensions = [];
        			else shipment.dimensions = JSON.parse(shipment.dimensions);
        		if(shipment.comments===null)shipment.comments = [];
        			else shipment.comments = JSON.parse(shipment.comments);
        		if(shipment.booking_date!==null)shipment.booking_date=moment.utc(shipment.booking_date);
        		if(shipment.ready_date!==null)shipment.ready_date=moment.utc(shipment.ready_date);
        		if(shipment.pickup_date!==null)shipment.pickup_date=moment.utc(shipment.pickup_date);
        		if(shipment.actual_pickup!==null)shipment.actual_pickup=moment.utc(shipment.actual_pickup);
        		if(shipment.eta!==null)shipment.eta=moment.utc(shipment.eta);
        		if(shipment.posit_date!==null)shipment.posit_date=moment.utc(shipment.posit_date);
        		if(shipment.customs_date!==null)shipment.customs_date=moment.utc(shipment.customs_date);
        		if(shipment.dropoff_date!==null)shipment.dropoff_date=moment.utc(shipment.dropoff_date)
						if(shipment.customer_additional_costs===null)shipment.customer_additional_costs = [];
        			else shipment.customer_additional_costs = JSON.parse(shipment.customer_additional_costs);
						if(shipment.carrier_additional_costs===null)shipment.carrier_additional_costs = [];
        			else shipment.carrier_additional_costs = JSON.parse(shipment.carrier_additional_costs);
				shipment.filesnames = JSON.parse(shipment.filesnames);
				console.log({ ...initialShipment, ...shipment });
				this.setState({ shipment : { ...initialShipment, ...shipment },changed_items : [],pdf_replace_delivery_address : false});
				this.switchTab();
        }).catch((error) => {
         	if(error)JSAlert.alert(error,null,JSAlert.Icons.Failed)
        });
	}
	performSearch(){
		var al = JSAlert.loader("<i class='fa fa-spinner fa-pulse fa-3x fa-fw'></i><br>Please wait...");
		var self = this;
		fetch(url+"api.php?search&data="+JSON.stringify(this.state.searchForm), {
		method: 'GET',
		credentials: 'include',
  		 })
		 .then((response) => response.json())
		 .then(
        (res) => {
        		al.dismiss()
        		if (res.status==="error"){
        			if (res.message==="Please login") {
        				JSAlert.alert("Session closed, please sign in again",null,JSAlert.Icons.Failed).then(()=>
        				 self.setState({
							user : {},
							userSignout : true,
							userLoged : false
				   	})
				   	)
        			}
        			else JSAlert.alert(res.message,null,JSAlert.Icons.Failed);
        			return;
        		}
				res = res.map((r)=>{
					let name = r.created_by.split(".");
					name = name[0][0].toUpperCase()+name[1][0].toUpperCase();
					return {...r,created_by:name}
				})
						if(typeof res !=="object"){
							JSAlert.alert("Received invalid response ("+(typeof res)+"), refresh results table manually",null,JSAlert.Icons.Failed);
							return;
						}
						let expandedRows = res.map((r)=>{ return r.special_instructions!==null&&r.special_instructions!==''});
						this.setState({ tableData : res, visibleTableData : res,expandedRows:expandedRows },()=>{
							this.applyFilters();
						} )
        }).catch((error) => {
         	if(error)JSAlert.alert(error,null,JSAlert.Icons.Failed)
        });
	}
	showDGNForm(){
		this.setState({dgn_visible : true})
	}
	hideDGNForm(){
		this.setState({dgn_visible : false})
	}
	getSelectedTableRows(){
		return this.state.selectedTableRows;
	}
	tableFilter(e){
		let inputs = {...this.state.tableFilterInputs};
		inputs[e.target.name]=e.target.value;

		this.setState({tableFilterInputs : inputs },()=>{
			this.applyFilters();
		});
	}
	applyFilters(){
		let data = [...this.state.tableData];
		let inputs = {...this.state.tableFilterInputs};
		Object.keys(inputs).forEach((key, i) => {
			if(inputs[key].length===0)return
			data = data.filter((r)=>{
				let val = r[key];
				if(val===null)return;
				let m = moment(val);
				if(m.isValid()===true){
					val=m.format("DD.MM.YYYY");
				}
				else{
					val=val.toLowerCase();
				}

				return val.indexOf(inputs[key].toLowerCase())!==-1
			})
		});
		let expandedRows = data.map((r)=>{ return r.special_instructions!==null&&r.special_instructions!==''});
		this.setState({visibleTableData : data,expandedRows:expandedRows });
		if(data.length===1000){
			this.createNotification("warning","Limit 1000 rows reached !!")
		}
		this.calcGMs();
	}
	clearTableFilters(){
		this.setState({tableFilterInputs : {...tableFiltersList} });
	}
	selectTableRow(e,props){
		let selected = [...this.state.selectedTableRows];
		if(e.target.checked==true){
			selected.push(props.original);
		}else {
			selected = selected.filter((r)=>{ return r.recnum!==props.original.recnum })
		}
		this.setState({ selectedTableRows:selected });
	}
	addressbookFilter=(e)=>{
		let data = [...this.state.addressBookData];
		data = data.filter((r)=>{ return r[e.target.name].toLowerCase().indexOf(e.target.value.toLowerCase())!==-1 });
		let inputs = this.state.addressbookFilterInputs;
		inputs[e.target.name]=e.target.value;
		if(e.target.name==="type"){
			inputs.name="";
		}
		if(inputs.type!==""){
			data = data.filter((r)=>{ return r.type === inputs.type });
		}
		this.setState({addressBookDataVisible:data,addressbookFilterInputs:inputs})
	}
	calcGMs = () =>{
		let sales_rate =0;
		let customer_extra_costs =0;
		let price =0;
		let carrier_extra_costs =0;
		this.state.visibleTableData.forEach((row)=>{
			sales_rate += row.sales_rate === null ? 0:parseFloat(row.sales_rate);
			customer_extra_costs += row.customer_extra_costs === null ? 0 :parseFloat(row.customer_extra_costs);
			price += row.price === null ? 0 :parseFloat(row.price);
			carrier_extra_costs += row.carrier_extra_costs ===null ? 0:parseFloat(row.carrier_extra_costs);
		})
		let gm = (((parseFloat(sales_rate)+parseFloat(customer_extra_costs))-(parseFloat(price)+parseFloat(carrier_extra_costs) ))/(parseFloat(sales_rate)+parseFloat(customer_extra_costs)))*100;
		this.setState({
			total_gm_percent : isNaN(gm) ===true ? "N/A":gm.toFixed(2),
			total_gm_eur : isNaN(gm) ===true ? "N/A":((parseFloat(sales_rate)+parseFloat(customer_extra_costs))-(parseFloat(price)+parseFloat(carrier_extra_costs) ))
		})

	}
	hideClientModal = (comment)=>{
		this.setState({ displayClientModal:false});
		if(comment!==undefined){
			this.setState( prevState =>({
				shipment : {
					...prevState.shipment,
					comments: [...prevState.shipment.comments,JSON.stringify(comment) ]
				}
			}))
		}
	}
	hideCarrierModal = (comment)=>{
		this.setState({ displayCarrierModal:false });
		if(comment!==undefined){
			this.setState( prevState =>({
				shipment : {
					...prevState.shipment,
					comments: [...prevState.shipment.comments,JSON.stringify(comment) ]
				}
			}))
		}
	}
	hideEmailModal = () =>{
		this.setState({ displayEmailModal : false})
	}
	hideUpdateModal = ()=>{
		this.setState({ displayUpdateModal:false });
	}
	sendMail = (to) =>{

		this.setState({btnDropDownShow:false,displayEmailModal:true,emailModalTo:to})
	}
	createShipment = (data)=>{
		console.log(data)
		this.setState({
				shipment : {...initialShipment,...data},
				isNewRecord : true,
				isEditRecord : false,
				changed_items : [],
			mainContainer : "d-none",
			detailContainer : "col-12",
			settingsContainer :"d-none",
			quotesListContainer : "d-none"
		})
		window.scrollTo(0,56)
	}
  render() {
		const styles ={
			headinput : {
				maxWidth : "100%"
			},
			tableCheckBox :{
				marginTop : "7px",
				marginLeft : "10px"
			}
		}
  const columns = [
		{
				Header: <div>{this.state.selectedTableRows.length}
									<br/><a href="#" onClick={()=>this.setState({selectedTableRows : this.state.visibleTableData })}>All</a>
									<br/><a href="#" onClick={()=>this.setState({selectedTableRows : [] })}>None</a>
								</div>,
				accessor: 'recnum',
				sortable : false,
				show : this.state.multiselect,
				width: 55,

				Cell : props => <input
													style={styles.tableCheckBox}
													type="checkbox"
													checked = {this.state.selectedTableRows.findIndex((r)=>{ return r.recnum ===props.value }) === -1 ? false:true}
													onClick={ (e)=>this.selectTableRow(e,props) }
												/>
			},
		{
	    Header: <button type="button" style={{marginTop:"8px"}} className="btn btn-sm btn-primary" onClick={()=>this.setState({multiselect : !this.state.multiselect})}>
								<i className={this.state.multiselect===true ? "fa fa-square-o":"fa fa-check-square-o"} />
							</button>,
	    accessor: 'recnum',
			sortable : false,
	    width: 60,
			Footer:() => (
		<span>
			Total: {this.state.visibleTableData.length}

		</span>
	),
	    Cell : props => <button className="btn btn-primary btn-sm" onClick={ () => this.openDetail(props.value) } id={props.value}>Open</button>
	  },{
    Header: <div><span title='Author of record'>Auth</span><br/><input style={styles.headinput} placeholder = "Filter" name="created_by" value = { this.state.tableFilterInputs.created_by } onChange={(e)=>this.tableFilter(e)} onClick={ (e)=>e.stopPropagation() } /></div>,
    accessor: 'created_by',
		width:50,
		Footer:() => (
			<span>

			</span>
		)
  }
  , {
    Header: <div>F4W Reference<br/><input style={styles.headinput} placeholder = "Filter" name="nl_ref" value = { this.state.tableFilterInputs.nl_ref } onChange={(e)=>this.tableFilter(e)} onClick={ (e)=>e.stopPropagation() } /></div>,
    accessor: 'nl_ref',
		width:120,
   // Cell: props => <span className='number'>{props.value}</span> // Custom cell components!

  }
	,{
    Header: <div>Type<br/><input style={styles.headinput} placeholder = "Filter" name="region" value = { this.state.tableFilterInputs.region } onChange={(e)=>this.tableFilter(e)} onClick={ (e)=>e.stopPropagation() } /></div>,
    accessor: 'region',
		width:60
  },{
	    Header: <div>Cargo</div>,
	    accessor: 'dimensions', // String-based value accessors!
			width:180,
			Footer:() => (
				<span>
					{(()=>{
						if(this.state.visibleTableData.length>0){
							let sum = 0;
							this.state.visibleTableData.forEach((object)=>{
								return sum+=parseFloat(object.total_weight);
							})
					return "Total (kg): "+ sum;
				}
				})()
				}
				</span>
			),
	    Cell : props =>  {

				let dims = JSON.parse(props.value);
				if(props.row.kind==="FCL"){
					let text = dims.map((r)=>{
						return r.length + "/"+ r.number;
					});
					return <span><div style={{ backgroundColor : props.row._original.customs_clearance==="t" ? "#d2b7d6":"", padding:"5px",width:"100%" }} data-tip={props.row._original.customs_clearance==="t"?"Customs clearance required":""}>{ text.join(",") }</div><ReactTooltip></ReactTooltip></span>
				}else {
					let text = dims.map((r)=>{
						return r.pieces + "x"+r.packing ;
					});
					return <span><div style={{ backgroundColor : props.row._original.customs_clearance==="t" ? "#d2b7d6":"", padding:"5px",width:"100%" }} data-tip={props.row._original.customs_clearance==="t"?"Customs clearance required":""}>{ text.join(",") }</div><ReactTooltip></ReactTooltip></span>
				}
			}
	  }
		,{
    Header: <div>Pickup date<br/><input style={styles.headinput} placeholder = "Filter" name="pickup_date" value = { this.state.tableFilterInputs.pickup_date } onChange={(e)=>this.tableFilter(e)} onClick={ (e)=>e.stopPropagation() } /></div>,
    accessor: 'pickup_date',
		width:140,
    Cell : props =>  props.value ? (moment(props.value).isSame(new Date(), "day")===true ? <b>{moment(props.value).format("DD.MM.YYYY")}</b> : moment(props.value).format("DD.MM.YYYY")): null,

  },
  	{
		Header: <div>Pickup zip<br/><input style={styles.headinput} placeholder = "Filter" name="pickup_zip" value = { this.state.tableFilterInputs.pickup_zip } onChange={(e)=>this.tableFilter(e)} onClick={ (e)=>e.stopPropagation() } /></div>,
		accessor: 'pickup_zip',
		width:70,
	}, 
	{
		Header: <div>Pickup country<br/><input style={styles.headinput} placeholder = "Filter" name="pickup_country" value = { this.state.tableFilterInputs.pickup_country } onChange={(e)=>this.tableFilter(e)} onClick={ (e)=>e.stopPropagation() } /></div>,
		accessor: 'pickup_country',
		width:70,
	},
  {
		Header: <div>Pickup city<br/><input style={styles.headinput} placeholder = "Filter" name="pickup_city" value = { this.state.tableFilterInputs.pickup_city } onChange={(e)=>this.tableFilter(e)} onClick={ (e)=>e.stopPropagation() } /></div>,
		accessor: 'pickup_city',
	}
	,{
    Header: <div>Posit date<br/><input style={styles.headinput} placeholder = "Filter" name="posit_date" value = { this.state.tableFilterInputs.posit_date } onChange={(e)=>this.tableFilter(e)} onClick={ (e)=>e.stopPropagation() } /></div>,
    accessor: 'posit_date',
		width:140,
    Cell: props => props.value ? (moment(props.value).isSame(new Date(), "day")===true ? <b>{moment(props.value).format("DD.MM.YYYY")}</b> : moment(props.value).format("DD.MM.YYYY")): null,
  },
  {
		Header: <div>Posit zip<br/><input style={styles.headinput} placeholder = "Filter" name="delivery_zip" value = { this.state.tableFilterInputs.delivery_zip } onChange={(e)=>this.tableFilter(e)} onClick={ (e)=>e.stopPropagation() } /></div>,
		accessor: 'delivery_zip',
		width:70,
	},
	{
		Header: <div>Posit country<br/><input style={styles.headinput} placeholder = "Filter" name="delivery_country" value = { this.state.tableFilterInputs.delivery_country } onChange={(e)=>this.tableFilter(e)} onClick={ (e)=>e.stopPropagation() } /></div>,
		accessor: 'delivery_country',
		width:70,
	},    
  {
		Header: <div>Posit city<br/><input style={styles.headinput} placeholder = "Filter" name="delivery_city" value = { this.state.tableFilterInputs.delivery_city } onChange={(e)=>this.tableFilter(e)} onClick={ (e)=>e.stopPropagation() } /></div>,
		accessor: 'delivery_city',
	}
  ,{
		Header: <div>Client name<br/><input style={styles.headinput} placeholder = "Filter" name="client_name" value = { this.state.tableFilterInputs.client_name } onChange={(e)=>this.tableFilter(e)} onClick={ (e)=>e.stopPropagation() } /></div>,
		accessor: 'client_name',
	},{
		Header: <div>Client ref<br/><input style={styles.headinput} placeholder = "Filter" name="client_reference" value = { this.state.tableFilterInputs.dropoff_city } onChange={(e)=>this.tableFilter(e)} onClick={ (e)=>e.stopPropagation() } /></div>,
		accessor: 'client_reference',
	},{
		Header: <div>Carrier<br/><input style={styles.headinput} placeholder = "Filter" name="carrier_name" value = { this.state.tableFilterInputs.carrier_name } onChange={(e)=>this.tableFilter(e)} onClick={ (e)=>e.stopPropagation() } /></div>,
		accessor: 'carrier_name',
	}
  ,{
    Header: <div>Status<br/><input style={styles.headinput} placeholder = "Filter" name="status" value = { this.state.tableFilterInputs.status } onChange={(e)=>this.tableFilter(e)} onClick={ (e)=>e.stopPropagation() } /></div>,
    accessor: 'status', // String-based value accessors!
    width: 80,
  }
	,{
    Header: <div>GM<span><br/>{this.state.total_gm_percent} %<br/>{this.state.total_gm_eur} €</span></div>,
    accessor: 'price', // String-based value accessors!
		width:70,
		Cell : props =>  {
			let sales_rate = props.row._original.sales_rate === null ? 0:props.row._original.sales_rate;
			let customer_extra_costs = props.row._original.customer_extra_costs === null ? 0 :props.row._original.customer_extra_costs;
			let price = props.row._original.price === null ? 0 :props.row._original.price;
			let carrier_extra_costs = props.row._original.carrier_extra_costs ===null ? 0:props.row._original.carrier_extra_costs;
			let gm = (((parseFloat(sales_rate)+parseFloat(customer_extra_costs))-(parseFloat(price)+parseFloat(carrier_extra_costs) ))/(parseFloat(sales_rate)+parseFloat(customer_extra_costs)))*100;

			return isNaN(gm) ===true ? "N/A":<span>{gm.toFixed(2)}%<br/>{((parseFloat(sales_rate)+parseFloat(customer_extra_costs))-(parseFloat(price)+parseFloat(carrier_extra_costs) )).toFixed(2)}€</span>;
		}
	}
];
   const addressBookColumns = [
 {
    Header: '',
    accessor: 'recnum',
    width: 130,
    Cell : props => (
    	<div className="btn-group">
    	<button className="btn btn-success" title="Select" onClick={ () => this.insertAddress(props.row) } ><i className="fa fa-check"/></button>
    	<button className="btn btn-danger" title="Delete" onClick={ () => this.deleteAddress(props.value) } ><i className="fa fa-trash"/></button>
    	<button className="btn btn-default" title="Edit" onClick={ () => this.editAddress(props.row) } ><i className="fa fa-pencil"/></button>
    	</div>)

  },
		{
		Header: <div>Type<br/>
				<select style={styles.headinput} className="form-control" placeholder = "Filter" name="type" value = { this.state.addressbookFilterInputs.type } onChange={(e)=>this.addressbookFilter(e)} onClick={ (e)=>e.stopPropagation() } >
					{this.state.addressbookFilterTypeOptions.map((o)=>{
						return <option value={o!=="All"?o:""}>{o}</option>
					})}
				</select>
			</div>,
		accessor: 'type'
		},
    {
    Header: <div>Name<br/><input style={styles.headinput} placeholder = "Filter" name="name" value = { this.state.addressbookFilterInputs.name } onChange={(e)=>this.addressbookFilter(e)} onClick={ (e)=>e.stopPropagation() } /></div>,
    accessor: 'name'
  },
{
    Header: <div>Street<br/><input style={styles.headinput} placeholder = "Filter" name="street" value = { this.state.addressbookFilterInputs.street } onChange={(e)=>this.addressbookFilter(e)} onClick={ (e)=>e.stopPropagation() } /></div>,
    accessor: 'street'
  },
 {
    Header:<div>City<br/><input style={styles.headinput} placeholder = "Filter" name="city" value = { this.state.addressbookFilterInputs.city } onChange={(e)=>this.addressbookFilter(e)} onClick={ (e)=>e.stopPropagation() } /></div>,
    accessor: 'city'
  },
  {
    Header: <div>Country<br/><input style={styles.headinput} placeholder = "Filter" name="country" value = { this.state.addressbookFilterInputs.country } onChange={(e)=>this.addressbookFilter(e)} onClick={ (e)=>e.stopPropagation() } /></div>,
    accessor: 'country'
  },
  {
    Header: <div>Zip<br/><input style={styles.headinput} placeholder = "Filter" name="zip" value = { this.state.addressbookFilterInputs.zip } onChange={(e)=>this.addressbookFilter(e)} onClick={ (e)=>e.stopPropagation() } /></div>,
    accessor: 'zip'
  },  {
    Header: 'Contact person',
    accessor: 'contact'
  },  {
    Header: 'Opening hours',
    accessor: 'opening_hours'
  },
	{
		Header: 'Customs clearance',
		accessor: 'customs_clearance',
	}]
const dimensionRows = this.state.shipment.dimensions.map((item)=>
{return item.packing!=="Container" ? (
<div className="form-group inline-inputs row">
		<div className="col-1 text-right">
			<button className="btn btn-danger" type="button" onClick={ ()=> this.delDimensionsRow(item.rowId) }>
				<span className="fa fa-minus"></span>
			</button>
		</div>
		<div className="col-3 col-md-1">
			<input className="form-control" name="packing" data-submit="exclude" type="hidden"/>
			<span className="form-control" name="packing">{item.packing}</span>
		</div>
		<div className="col-4 col-md-2">
			<div className="input-group">
				<input className="form-control" type="number" min="0" name="length" value={item.length} onChange={ (e)=> this.updateDimensionRow(e,item.rowId) } placeholder='Length'/>
				<div className="input-group-append">
					<span className="input-group-text">cm</span>
				</div>
			</div>
		</div>
		<div className="col-4 col-md-2">
			<div className="input-group">
				<input className="form-control" type="number" min="0" name="width" value={item.width} onChange={ (e)=> this.updateDimensionRow(e,item.rowId) } placeholder='Width'/>
				<div className="input-group-append">
					<span className="input-group-text">cm</span>
				</div>
			</div>
		</div>
		<div className="col-4 col-md-2">
			<div className="input-group">
				<input className="form-control" type="number" min="0" name="height" value={item.height} onChange={ (e)=> this.updateDimensionRow(e,item.rowId) } placeholder='Height'/>
				<div className="input-group-append">
					<span className="input-group-text">cm</span>
				</div>
			</div>
		</div>
		<div className="col-4 col-md-2">
			<div className="input-group">
				<input className="form-control" type="number" min="0" name="pieces" value={item.pieces} onChange={ (e)=> this.updateDimensionRow(e,item.rowId) } placeholder='Number of pieces'/>
				<div className="input-group-append">
					<span className="input-group-text">pcs</span>
				</div>
			</div>
		</div>
		<div className="col-4 col-md-2">
			<div className="input-group">
				<input className="form-control" type="text" min="0" name="weight" value={item.weight} onChange={ (e)=> this.updateDimensionRow(e,item.rowId) } placeholder='Weight per piece'/>
				<div className="input-group-append">
					<span className="input-group-text">kg</span>
				</div>
			</div>
		</div>

</div>
):(
	<div className="form-group inline-inputs row dimensionRow">
			<div className="col-1 text-right">
				<span className="text-muted small"> </span>
				<button className="btn btn-danger" type="button" onClick={ ()=> this.delDimensionsRow(item.rowId) }>
					<span className="fa fa-minus"></span>
				</button>
			</div>
			<div className="col-3 col-md-1">
				<span className="text-muted small"> </span>
				<input className="form-control" name="packing" data-submit="exclude" type="hidden"/>
				<span className="form-control" name="packing">{item.packing}</span>
			</div>
			<div className="col-4 col-md-1">
				<span className="text-muted small">Size</span>
				<div className="input-group">
				<select className="form-control" value={item.length} name="length" style={{width:"100%"}} onChange={ (e)=> this.updateDimensionRow(e,item.rowId) }>
					<option>20"</option>
					<option>30"</option>
					<option>40"</option>
					<option>40" HC</option>
					<option>45" HC</option>
					<option>45" HW</option>
					<option>45" PW</option>
					<option>20" Flatrack</option>
					<option>40" Flatrack</option>
					<option>20" Reef</option>
					<option>40" Reef</option>
					<option>20" OT</option>
					<option>40" OT</option>
					<option>Breakbulk</option>
					<option>nonstandard</option>
				</select>
				</div>
			</div>
			<div className="col-4 col-md-1">
				<span className="text-muted small">Tare weight</span>
				<div className="input-group">
					<input className="form-control" type="text" name="tare" value={item.tare} onChange={ (e)=> this.updateDimensionRow(e,item.rowId) } placeholder='Tare weight'/>
					<div className="input-group-append">
						<span className="input-group-text">kg</span>
					</div>
				</div>
			</div>
			<div className="col-4 col-md-2">
				<span className="text-muted small">Container number</span>
				<div className="input-group">
					<input className="form-control" type="text" name="number" value={item.number} onChange={ (e)=> this.updateDimensionRow(e,item.rowId) } placeholder='Container number'/>
				</div>
			</div>
			<div className="col-4 col-md-2">
				<span className="text-muted small">Seal number</span>
				<div className="input-group">
					<input className="form-control" type="text" name="seanumber" value={item.seanumber} onChange={ (e)=> this.updateDimensionRow(e,item.rowId) } placeholder='Seal number of container'/>
				</div>
			</div>
			<div className="col-4 col-md-2">
				<span className="text-muted small">Equipment</span>
				<div className="input-group">
					<input className="form-control" type="text" name="carrier" value={item.carrier} onChange={ (e)=> this.updateDimensionRow(e,item.rowId) } placeholder='Equipment'/>
				</div>
			</div>
			<div className="col-4 col-md-2">
				<span className="text-muted small">Weight of goods</span>
				<div className="input-group">
					<input type="hidden" name="pieces" value="1"/>

					<input className="form-control" type="text" name="weight" value={item.weight} onChange={ (e)=> this.updateDimensionRow(e,item.rowId) } placeholder='Weight of goods'/>
					<div className="input-group-append">
						<span className="input-group-text">kg</span>
					</div>
				</div>
			</div>
	</div>
)}
);
var previous_comments = this.state.shipment.comments.map((item)=>{
	var data = JSON.parse(item);
	data.date = moment(data.date).format("DD.MM.YYYY");
	return <li className="list-group-item">{data.date} : {data.type!==undefined?data.type+" comment :":""} { data.text }</li>
});
if (this.state.shipment.comments.length===0) {
	previous_comments = <li className="list-group-item">No comments..</li>
}
if(this.state.userLoged===false)return ( <Login onLoginSuccess={this.loginSuccess} value={ this.state.userSignout } url={url}/> );
if(this.state.userLoged===true)return (
      <div className="App">
<nav className="navbar navbar-expand-lg navbar-dark bg-dark">
  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" aria-expanded="false" aria-label="Toggle navigation" onClick={this.showMenu}>
    <span className="navbar-toggler-icon"></span>
  </button>
  <a className="navbar-brand" href="#">F4W</a>

  <div className={"navbar-collapse "+ this.state.menu_collapse } aria-expanded="true">
    <ul className="navbar-nav ml-auto mt-2 mt-lg-0">
      <li className="nav-item">
        <a className="nav-link" href="#" onClick={ (e)=>this.switchTabDva(e) }><span className="fa fa-list"> Shipments</span></a>
      </li>
      <li className="nav-item">
        <a className="nav-link" href="#" onClick={ ()=>this.switchTab("settings") }><span className="fa fa-envelope"></span>{this.state.msgCount>0 &&(<span className="badge">{this.state.msgCount}</span>)}</a>
      </li>

      <li className="nav-item">
        <a className="nav-link" href="#" onClick={ this.signout }><span className="fa fa-sign-out"></span> Signout : { this.state.user.name } ({ this.state.user.role })</a>
      </li>
    </ul>
  </div>
</nav>
		<div className="container-fluid mt-2">
      	<div className="row">
				{this.state.mainContainer==="col-12"&&(
   	   	<div id="main_container" className = { this.state.mainContainer }>

   	   		<div id="filters" className="row">
						<div className="col-12 new-record-wrap">
   	   				{this.state.user.canCreateRecord === true && (
	  		   				<button type="button" className="btn btn-success" onClick={()=>this.switchTab("new") }>Create new record</button>
	  		   			)}
								<button className="btn btn-primary float-right" onClick={()=>this.showDGNForm()}><i className="fa fa-free-code-camp" aria-hidden="true"/> DGN</button>
							</div>
   	   			<form className="form-inline" onSubmit={()=>{}}>
	   	   			<div className="col-12 col-lg-6">
			   				<div className="form-group ">
			   					<div className="control-label col-12 col-md-3" >Type</div>
			   					<div className="col-md-9">
										<select className="form-control" name="region" value={ this.state.searchForm.region } onChange = { (e)=> this.searchFormHandleChange(e) }>
											<option value="">All</option>
											<option value="Import">Import</option>
											<option value="Export">Export</option>

										</select>
			   					</div>
			   				</div>
							{/*
								<div className="form-group">
									<div className="control-label col-12 col-md-3">Quotation number</div>
									<div className="col-md-9">
										<input type="text" className="form-control" name="quotation_number" value={ this.state.searchForm.quotation_number } onChange = { (e)=> this.searchFormHandleChange(e) }></input>
									</div>
								</div>
					*/}
								<div className="form-group">
									<div className="control-label col-12 col-md-3">F4W Reference</div>
									<div className="col-md-9">
										<input type="text" className="form-control" name="nl_ref" value={ this.state.searchForm.nl_ref } onChange = { (e)=> this.searchFormHandleChange(e) }></input>
									</div>
								</div>
	   	   				<div className="form-group">
	   	   					<div className="control-label col-3">Status</div>
	   	   					<div className="col-md-9">
	   	   						<select className="form-control" name="status" value={ this.state.searchForm.status } onChange = { (e)=> this.searchFormHandleChange(e) }>
										<option value="">All</option>
										<option value="Created">Created</option>
										<option value="Send">Send</option>
										<option value="Ready">Ready</option>
										<option value="Finished">Finished</option>
										<option value="CMR">CMR</option>
										<option value="Cancelled">Cancelled</option>
										<option value="Invoiced">Invoiced</option>
									</select>
	   	   					</div>
	   	   				</div>
	   	   		</div>
  		   			<div className="col-12 col-lg-6">
  		   				<div className="col-12">
									<div className="form-group">
		   	   					<div className="control-label col-12 col-md-3">Client Reference</div>
		   	   					<div className="col-md-9">
		   	   						<input type="text" className="form-control" name="client_reference" value={ this.state.searchForm.client_reference } onChange = { (e)=> this.searchFormHandleChange(e) }></input>
		   	   					</div>
		   	   				</div>
		   	   				<div className="form-group">
		   	   					<div className="control-label col-12 col-md-3">Client Name</div>
		   	   					<div className="col-md-9">
		   	   						<input type="text" className="form-control" name="client_name" value={ this.state.searchForm.client_name } onChange = { (e)=> this.searchFormHandleChange(e) }></input>
		   	   					</div>
		   	   				</div>
		   	   				<div className="form-group">
		   	   					<div className="control-label col-12 col-md-3">Carrier name</div>
		   	   					<div className="col-md-9">
		   	   						<input type="text" className="form-control" name="carrier_name" value={ this.state.searchForm.carrier_name } onChange = { (e)=> this.searchFormHandleChange(e) }></input>
		   	   					</div>
		   	   				</div>
									<div className="form-group">
										<div className="control-label col-12 col-md-3">Posit month & year</div>
										<div className="col-md-9">
											<select className="form-control col-6" name="currentMonth" value={ this.state.searchForm.currentMonth } onChange = { (e)=> this.searchFormHandleChange(e) }>
												<option value="0">All</option>
												<option value="1">January</option>
												<option value="2">February</option>
												<option value="3">March</option>
												<option value="4">April</option>
												<option value="5">May</option>
												<option value="6">June</option>
												<option value="7">July</option>
												<option value="8">August</option>
												<option value="9">September</option>
												<option value="10">October</option>
												<option value="11">November</option>
												<option value="12">December</option>
											</select>
											<select className="form-control col-6" name="currentYear" value={ this.state.searchForm.currentYear } onChange = { (e)=> this.searchFormHandleChange(e) }>
												<option value="0">All</option>
												{(()=>{
													let a=[];
													for(let i = 2022; i<moment().year()+2;i++){
														a.push(i)
													}
											 		return a.map((b,i)=>{ return <option value={b} key={i}>{b}</option>  })
												})()
												}
											</select>
										</div>
									</div>
	   	   				<p className="text-muted">* Maximum results is limited to 1000 rows</p>
   	   				</div>
  		   			</div>
  		   			<div className="form-group col-12 mt-1">
  		   				<div className="btn-group col-12" id="searchFormButtons">
	  		   			<button type="button" className="btn btn-primary col-sm-3" onClick={this.performSearch }><i className="fa fa-search"></i> Search</button>
	  		   			<button type="button" className="btn btn-secondary" onClick={this.resetSearch }><i className="fa fa-eraser"></i><span className="d-none d-sm-inline-block">Reset filters</span></button>
			            <ExcelFile filename="Export" element={<button type="button" className="btn btn-default"><i className="fa fa-download"></i><span className="d-none d-sm-inline-block">Download Excel</span></button>}>
			                <ExcelSheet data={this.state.tableData} name="Shipments" >
									  			<ExcelColumn label="F4W Reference" value="nl_ref"/>
			                 
			                    <ExcelColumn label="Pickup date" value={ (col) => { return col.pickup_date===null ? "": moment(col.pickup_date).format("DD.MM.YYYY"); } }/>
			                    <ExcelColumn label="Posit date" value={ (col) => { return col.posit_date===null ? "": moment(col.posit_date).format("DD.MM.YYYY"); } }/>
			                    <ExcelColumn label="Dropoff date" value={ (col) => { return col.dropoff_date===null ? "": moment(col.dropoff_date).format("DD.MM.YYYY HH:mm"); } }/>
			                    <ExcelColumn label="Status" value="status" />
								<ExcelColumn label="Pickup city" value="pickup_city" />
								<ExcelColumn label="Pickup zip" value="pickup_zip" />
								<ExcelColumn label="Delivery city" value="delivery_city" />
								<ExcelColumn label="Delivery zip" value="delivery_zip" />
													<ExcelColumn label="Sales rate"  value={ (col) => { return col.sales_rate.toString().replace(".",",") } } />
													<ExcelColumn label="Customer extra costs"  value={ (col) => { return col.customer_extra_costs.toString().replace(".",",") } } />
													<ExcelColumn label="Buying rate" value={ (col) => { return col.price.toString().replace(".",",") } } />
													<ExcelColumn label="Carrier extra costs"  value={ (col) => { return col.carrier_extra_costs.toString().replace(".",",") } } />
													<ExcelColumn label="Profit €" value={ (col) =>{
														let sales_rate = col.sales_rate === null ? 0:col.sales_rate;
														let customer_extra_costs = col.customer_extra_costs === null ? 0 :col.customer_extra_costs;
														let price = col.price === null ? 0 :col.price;
														let carrier_extra_costs = col.carrier_extra_costs ===null ? 0:col.carrier_extra_costs;
														return ((parseFloat(sales_rate)+parseFloat(customer_extra_costs))-(parseFloat(price)+parseFloat(carrier_extra_costs) )).toFixed(2).toString().replace(".",",")
													}} />
													<ExcelColumn label="Total out" value={ (col) =>{
														let price = col.price === null ? 0 :col.price;
														let carrier_extra_costs = col.carrier_extra_costs ===null ? 0:col.carrier_extra_costs;
														return (parseFloat(price)+parseFloat(carrier_extra_costs) ).toFixed(2).toString().replace(".",",")
													}} />
													<ExcelColumn label="Total in" value={ (col) =>{
														let sales_rate = col.sales_rate === null ? 0:col.sales_rate;
														let customer_extra_costs = col.customer_extra_costs === null ? 0 :col.customer_extra_costs;
														return (parseFloat(sales_rate)+parseFloat(customer_extra_costs)).toFixed(2).toString().replace(".",",")
													}} />
													<ExcelColumn label="Customs costs"  value={ (col) => { return col.customs_costs!==null ? col.customs_costs.toString().replace(".",","):"" } } />
			                    <ExcelColumn label="Dimensions" value={ (col)=> {
			                    		var e = JSON.parse(col.dimensions)
			                    	 	var r = []
			                    	 	e.forEach((s)=> { r.push(s.pieces +" * "+s.packing+" : "+s.length+"x"+s.width+"x"+s.height+" - "+s.weight+"kg") } );
			                    	 	return r.join(",");
									 	 }} />
			                </ExcelSheet>
			            </ExcelFile>
	  		   			</div>
  		   			</div>
  		   		</form>
   	   		</div>
				  <ReactTable
				  	className="-highlight maintable row"
				    data={this.state.visibleTableData}
						pageSize = {this.state.visibleTableData.length}
				    columns={columns}
					//	showPaginationTop= {true}
					showPagination={false}
						sorted={[{
					      id: 'posit_date',
					      desc: true
					    }]}
						getTrProps={(state, rowInfo, column) => {
						    if (rowInfo === undefined) {
						        return {}
						    }
								let color;
								switch(rowInfo.original.status){
									case "Send":
										color="#ffc000";
									break;
									case "CMR":
										color="#b4c7e7";
									break;
									case "Ready":
										color="#a9d18e";
									break;
									case "Cancelled":
										color="#c00000";
									break;
									case "Invoiced":
										color="#7f7f7f";
									break;
									case "Finished":
										color="#457edf";
									break;
									default:
									break
								}
						    return {
										style:{"backgroundColor":color}
						        //'data-visible': (rowInfo.original.special_instructions!==null && rowInfo.original.special_instructions!=='') ? true : false
						    }
						}}
						SubComponent= { (row)=>{

							let color;
							switch(row.original.status){
								case "Send":
									color="#ffc000";
								break;
								case "CMR":
									color="#b4c7e7";
								break;
								case "Ready":
									color="#a9d18e";
								break;
								case "Cancelled":
									color="#c00000";
								break;
								case "Invoiced":
									color="#7f7f7f";
								break;
								case "Finished":
									color="#457edf";
								break;
								default:
								break
							}
								return(
									<div className={row.index%2===0 ? "-odd":""}>
										<div className="row p-2" style={{"backgroundColor":color}}>

											<div className="col-6 col-xs-12" >
												<p>Special instuctions : {row.original.special_instructions}</p>

												<div className="" style={{"backgroundColor":color}}>Internal notes/comments
												{row.original.comments!==undefined&&row.original.comments!==null&&(
													<div className="pl-2">
													{JSON.parse(row.original.comments).map((r)=>{
														r= JSON.parse(r);
														return <p>{moment(r.date).format("DD.MM.YYYY") + " : "+r.text}</p>
													})}
													<NotesDownload className="btn btn-default" job={ row.original.nl_ref } rows={ JSON.parse(row.original.comments) }/>
													</div>
													)}

												</div>

											</div>
											<div className="col-6 col-xs-12" >
												<p>Pickup Reference : {row.original.collection_reference} </p>
												<p>Posit Reference : {row.original.delivery_reference} </p>
												<p>Dropoff Reference : {row.original.dropoff_reference} </p>
											</div>
										</div>
									</div>
								)
							}
						}
				  />
   	   	</div>
				)}
   	   	<div id="settings_container" className = { this.state.settingsContainer }>
					{this.state.settingsContainer==="col-12"&&(
					<Messages
					 	url={url}
						createNotification={this.createNotification}
						changeMsgCount = {this.changeMsgCount}/>
					)}
   	   	</div>
	      	<div id="detail_container" className = { this.state.detailContainer }>
					<div className="col-12">
	 	 		    	<button className="btn btn-primary" type="button" onClick={this.switchTabDva}><i className="fa fa-undo"/> Back</button>
	 	 		    	{this.state.user.role==="admin" && this.state.isNewRecord!==true && (
							<span className="btn-group pull-right">
								<button type="button" className="btn btn-success" onClick={ this.updateRecord }><i className="fa fa-save"/> Update record</button>
								<button className={"btn btn-secondary"+(this.state.isNewRecord ? 'd-none' : '')} type="button" onClick={()=>this.copyRecord(this.state.shipment.recnum)}><i className="fa fa-copy"/> Copy record</button>
	 	 		    		<button className={"btn btn-danger "+(this.state.isNewRecord ? 'd-none' : '')} type="button" onClick={()=>this.deleteRecord(this.state.shipment.recnum)}><i className="fa fa-trash"/> Delete record</button>
								<div class="btn-group">
									 <button type="button" class="btn btn-secondary dropdown-toggle" data-toggle="dropdown"
									 		onClick={()=>{
												this.setState({btnDropDownShow:!this.state.btnDropDownShow})
											}}>
										 <i className="fa fa-envelope"/> Send email
									 </button>
									 <div class="dropdown-menu" style={{display: this.state.btnDropDownShow===true?"block":"none"}}>
										 <a class="dropdown-item" href="#a" onClick={()=>{ this.sendMail("carrier") }}>To carrier</a>
										 <a class="dropdown-item" href="#b" onClick={()=>{ this.sendMail("client") }}>To client</a>
									 </div>
									</div>
							</span>
						)}
	 	 		    </div>

	      		<form className="" onSubmit={()=>{}}>
						<h5>General</h5>
						<hr/>
	   				<div className="form-group col-12 row">
							<div className="col-12 col-md-3">
		   					<div className="control-label" >Type</div>
		   					<div className="">
									<select className="form-control" name="region" value={ this.state.shipment.region } onChange = { (e)=> this.shipmentHandleChange(e) }>
											<option value="Import">Import</option>
											<option value="Export">Export</option>

									</select>
		   					</div>
							</div>
							{/*
							<div className="col-12 col-md-3">
							<div className="form-group ">
								<div className="control-label" >Kind of transport</div>
									<select className="form-control" name="kind" value={ this.state.shipment.kind } onChange = { (e)=> this.shipmentHandleChange(e) }>
										<option value="">Select</option>
										<option value="FTL" disabled={this.state.shipment.region==="Domestic" ? false:true}>FTL</option>
										<option value="FCL" disabled={this.state.shipment.region==="Domestic" ? true:false}>FCL</option>
										<option value="LTL" disabled={this.state.shipment.region==="Domestic" ? false:true}>LTL</option>
										<option value="LCL" disabled={this.state.shipment.region==="Domestic" ? true:false}>LCL</option>
										<option value="Rail & Road" disabled={this.state.shipment.region==="Domestic" ? true:false}>Rail & Road</option>
									</select>
							</div>
							</div>
							*/}
							<div className="col-12 col-md-3">
							<div className="form-group ">
		   					<div className="control-label col-3" >Status</div>
									<select className="form-control" name="status" value={ this.state.shipment.status } onChange = { (e)=> this.shipmentHandleChange(e) }>
										<option value="Created">Created</option>
										<option value="Send">Send</option>
										<option value="Ready">Ready</option>
										<option value="Finished">Finished</option>
										<option value="CMR">CMR</option>
										<option value="Cancelled">Cancelled</option>
										<option value="Invoiced">Invoiced</option>
									</select>
		   				</div>
							</div>
	   				</div>
						<div className="row col-12">
							{/*
								<div className="form-group col-12 col-md-6 col-lg-3">
									<div className="control-label ">Quotation Number</div>
									<div className="">
										<input type="text" className="form-control" value={ this.state.shipment.quotation_number } name="quotation_number" onChange= { (e)=> this.shipmentHandleChange(e) } ></input>
									</div>
								</div>
							*/}
			   				<div className="form-group col-12 col-md-6 col-lg-3">
			   					<div className="control-label ">F4W Reference</div>
			   					<div className="">
			   						<input type="text" className="form-control" value={ this.state.shipment.nl_ref } name="nl_ref" onChange= { (e)=> this.shipmentHandleChange(e) } ></input>
			   					</div>
			   				</div>

	  							{ this.state.isEditRecord===true  && (
	  								this.state.user.role==="admin" && (
			   				<div className="form-group col-12 col-md-6 col-lg-3">
			   					<div className="control-label ">Soot Reference</div>
			   					<div className="">
			   						<input type="text" className="form-control" readOnly="readonly" value={ "F4WRCM"+this.state.shipment.recnum } onChange= { (e)=> { return false } }></input>
			   					</div>
			   				</div>

		   				))}
						</div>
						<h5>Client / Customer</h5>
						<hr/>
						<div className="row col-12">
							<div className="form-group col-lg-5 col-12">
		   					<div className="control-label">Client name</div>
		   					<div className="">
		   						<input type="text" className="form-control" value={ this.state.shipment.client_name } name="client_name" onChange= { (e)=> this.shipmentHandleChange(e) } ></input>
		   					</div>
		   				</div>
							<div className="form-group col-lg-5 col-12">
								<div className="control-label">Client email(s)
									<i className="fa fa-question-circle-o text-muted" style={{marginLeft:"10px"}} data-tip="Recipients of auto generated messages. Enter valid email addresses separated by commas"></i><ReactTooltip ></ReactTooltip>
								</div>
								<div className="">
									<input type="text" placeholder="email addresses separated by commas" className="form-control" value={ this.state.shipment.notice_emails } name="notice_emails" onChange= { (e)=> this.shipmentHandleChange(e) }></input>
								</div>
							</div>
							<div className="col-2" >
								<div className="btn-group" style={{marginTop:"24px"}}>
									<button className="btn btn-primary" type="button" name="client" onClick={ ()=> this.openAddressBook("client") }><i className="fa fa-address-book" /> <span className="d-none d-sm-inline-block">Open</span></button>
									<button className="btn btn-success" type="button"  name="client" onClick={ ()=>this.addToAddressBook("client") }><i className="fa fa-plus" /> <span className="d-none d-sm-inline-block">Add</span></button>
								</div>
							</div>
						</div>
						<div className="form-group">
							<div className="control-label col-12 col-md-3">Client references</div>
							<div className="col-12 col-md-6 col-lg-3">
									<ListEl
										value={ this.state.shipment.client_reference }
										name="client_reference"
										onChange= { (e)=> this.shipmentHandleChange(e) }
										maxLength="10"
									/>
							</div>
						</div>
						<div className="row col-12">
							<div className="form-group col-12 col-md-3">
								<div className="control-label">Sales rate
									<i className="fa fa-question-circle-o text-muted" style={{marginLeft:"10px"}} data-tip="Only numbers, dot as decimal separator, 2 decimal places max"></i><ReactTooltip ></ReactTooltip>
								</div>
								<div className="input-group">
									<div className="input-group-prepend">
										<span className="input-group-text">€</span>
									</div>
									<input className="form-control"
										placeholder="0.00"
										name="sales_rate" value={ this.state.shipment.sales_rate } onChange = { (e)=> this.shipmentHandleChange(e) }/>
								</div>
							</div>
							<div className="form-group col-12 col-md-3">
								<div className="control-label">Customer extra costs
									<i className="fa fa-question-circle-o text-muted" style={{marginLeft:"10px"}} data-tip="This is a sum of additional costs below"></i><ReactTooltip ></ReactTooltip>
								</div>
								<div className="input-group">
									<div className="input-group-prepend">
										<span className="input-group-text">€</span>
									</div>
									<input className="form-control"
										placeholder="0.00"
										name="customer_extra_costs"
										readOnly
										value={ this.state.shipment.customer_extra_costs } onChange = { (e)=> this.shipmentHandleChange(e) }/>
								</div>
							</div>
							<div className="col-12 mt-1 mb-1">
								Customer's Additional costs
								<AdditionalCosts
									data={this.state.shipment.customer_additional_costs}
									updateState={this.shipmentHandleChange}
									name="customer_additional_costs"
									sumfield="customer_extra_costs"/>
							</div>
						</div>
						<div className="form-group">
							<div className="control-label col-6 col-md-3">Comments for client</div>
							<div className="col-12 col-md-9">
								<textarea type="text" className="form-control" name="client_comments" value={ this.state.shipment.client_comments } onChange = { (e)=> this.shipmentHandleChange(e)}></textarea>
								<span className="text-muted small">Text written here will be visible in comments section below</span>
							</div>
						</div>
						{ this.state.isEditRecord===true  && (
							<div className="btn-group col-12">
								<button type="button" className="btn btn-primary " onClick= { ()=>this.sendClientBooking("p") } ><i className="fa fa-envelope"/> Send Booking Confirmation to client</button>
							</div>
						)}

						<h5>Carrier & Rates</h5>
						<hr/>
						<div className="row col-12">
							<div className="form-group col-lg-5 col-12">
								<div className="control-label ">Carrier name</div>
								<div className="">
									<input type="text" className="form-control" value={ this.state.shipment.carrier_name } name="carrier_name" onChange= { (e)=> this.shipmentHandleChange(e) } ></input>
								</div>
							</div>
							<div className="form-group col-lg-5 col-12">
								<div className="control-label">Carrier email(s)
									<i className="fa fa-question-circle-o text-muted" style={{marginLeft:"10px"}} data-tip="Recipients of auto generated messages. Enter valid email addresses separated by commas"></i><ReactTooltip ></ReactTooltip>
								</div>
								<div className="">
									<input type="text" placeholder="email addresses separated by commas" className="form-control" value={ this.state.shipment.carrier_emails } name="carrier_emails" onChange= { (e)=> this.shipmentHandleChange(e) }></input>
								</div>
							</div>
							<div className="col-2" >
								<div className="btn-group" style={{marginTop:"24px"}}>
									<button className="btn btn-primary" type="button" name="carrier" onClick={ ()=> this.openAddressBook("carrier") }><i className="fa fa-address-book" /> <span className="d-none d-sm-inline-block">Open</span></button>
									<button className="btn btn-success" type="button"  name="carrier" onClick={ ()=>this.addToAddressBook("carrier") }><i className="fa fa-plus" /> <span className="d-none d-sm-inline-block">Add</span></button>
								</div>
							</div>
						</div>
						<div className="row col-12">
							<div className="form-group col-12 col-md-3">
								<div className="control-label ">Buying rate
									<i className="fa fa-question-circle-o text-muted" style={{marginLeft:"10px"}} data-tip="Only numbers, dot as decimal separator, 2 decimal places max"></i><ReactTooltip ></ReactTooltip>
								</div>
								<div className="input-group">
									<div className="input-group-prepend">
										<span className="input-group-text">€</span>
									</div>
										<input className="form-control"
											placeholder="0.00"
											name="price" value={ this.state.shipment.price } onChange = { (e)=> this.shipmentHandleChange(e) }/>
								</div>
							</div>

							<div className="form-group col-12 col-md-3">
								<div className="control-label">Carrier extra costs
									<i className="fa fa-question-circle-o text-muted" style={{marginLeft:"10px"}} data-tip="This is a sum of additional costs below"></i><ReactTooltip ></ReactTooltip>
								</div>
								<div className="input-group">
									<div className="input-group-prepend">
										<span className="input-group-text">€</span>
									</div>
									<input className="form-control"
										placeholder="0.00"
										readOnly
										name="carrier_extra_costs" value={ this.state.shipment.carrier_extra_costs } onChange = { (e)=> this.shipmentHandleChange(e) }/>
								</div>
							</div>
							<div className="col-12 mt-1 mb-1">
								Carrier's Additional costs
								<AdditionalCosts
									data={this.state.shipment.carrier_additional_costs}
									updateState={this.shipmentHandleChange}
									name="carrier_additional_costs"
									sumfield="carrier_extra_costs"/>
							</div>
						</div>
						<div className="form-group">
							<div className="control-label col-6 col-md-3">Comments for carrier</div>
							<div className="col-12 col-md-9">
								<textarea type="text" className="form-control" name="carrier_comments" value={ this.state.shipment.carrier_comments } onChange = { (e)=> this.shipmentHandleChange(e)}></textarea>
								<span className="text-muted small">Text written here will be visible in comments section below</span>
							</div>
						</div>

						{ this.state.isEditRecord===true  && (
							<div className="btn-group col-12">
								<button type="button" className="btn btn-secondary " onClick= { ()=>this.printShipment("p") } ><i className="fa fa-print"/> Print Freight Booking Confirmation</button>
								<button type="button" className="btn btn-primary " onClick= { ()=>this.sendShipment("p") } ><i className="fa fa-envelope"/> Send Booking Confirmation to carrier</button>
							</div>
						)}
						<h5>Cargo</h5>
						<hr/>
						<div className="form-group">
							<label className="control-label col-3">Packing</label>
							<div className=" col-12 row">
								<div className="col-12 col-md-6 col-lg-3">
									<select className="form-control" name="packing" onChange={ (e)=> this.setState({ shipment_selected_packing : e.target.value }) }>
										<option value="Pallet">Pallet</option>
										<option value="Box">Box</option>
										<option value="Container">Container</option>
										<option value="Case">Case</option>
										<option value="Bag">Bag</option>
										<option value="Sack">Sack</option>
										<option value="trunk">Trunk</option>
										<option value="Other">Other</option>
									</select>
								</div>
								<div className="col-3">
									<button type="button" className="btn btn-primary" onClick={ this.addDimensionsRow }>
										<span className="fa fa-plus"></span>
									</button>
								</div>
							</div>
						</div>
							{ dimensionRows }
	   				<div className="form-group">
	   					<div className="control-label col-6 col-md-3">Total Weight</div>
	   					<div className="col-12 col-md-6 col-lg-3">
	   						<input type="text" className="form-control" value={ this.state.shipment.total_weight } readOnly></input>
	   					</div>
	   				</div>
	   				<div className="form-group">
	   					<div className="control-label col-3">Pieces</div>
	   					<div className="col-12 col-md-6 col-lg-3">
	   						<input type="text" className="form-control" value={ this.state.shipment.pieces } readOnly></input>
	   					</div>
	   				</div>
					{/*
						<div className="row col-12">
							<div className="form-group col-12 col-md-3">
								<div className="control-label">Vessel name</div>
								<div className="">
									<input type="text" className="form-control" value={ this.state.shipment.vessel_name } name="vessel_name"  onChange = { (e)=> this.shipmentHandleChange(e)}></input>
								</div>
							</div>
							<div className="form-group col-12 col-md-3">
								<div className="control-label">ETA</div>
								<div className="">
								<DatePicker className="form-control"
									selected = { this.state.shipment.eta }
										onChange= { (val) => this.shipmentHandleChange({ target : { name : "eta", value : val } }) }
										dateFormat = "DD.MM.YYYY"
										placeholderText = "Click to select date"
								/>
								</div>
							</div>
						</div>
						*/}
						<div className="form-group">
							<div className="control-label col-6 col-md-3">Goods description</div>
							<div className="col-12 col-md-9">
								<textarea type="text" className="form-control" name="goods_description" value={ this.state.shipment.goods_description } onChange = { (e)=> this.shipmentHandleChange(e)}></textarea>
							</div>
						</div>

		   				<div className="form-group col-lg-3 col-12">
		   					<div className="control-label">DG / Non DG</div>
		   					<div className="">
		   						<select className="form-control" name="danger_goods" value={ this.state.shipment.danger_goods } onChange = { (e)=> this.shipmentHandleChange(e) }>
		   							<option value="">Please select</option>
		   							<option value="dg">DG</option>
		   							<option value="nondg">Non DG</option>
		   						</select>
		   					</div>
		   				</div>
							<div className="row col-12">
							<div className="form-group col-lg-3 col-12">
								 <div className="control-label">Temperature required</div>
								 <div className="">
								 <div className="toggle-switch toggle-switch-primary" style={{marginTop:"5px"}}>
									<label>
										<input type="checkbox" className="form-check-input" name="temperature_required" checked={this.state.shipment.temperature_required} onChange = { (e)=> this.shipmentHandleChange(e) } />
										<div className="toggle-switch-inner yesno"></div>
										<div className="toggle-switch-switch"><i className="fa fa-check"></i></div>
									</label>
								</div>
								</div>
							</div>
							{this.state.shipment.temperature_required===true && (
								<div className="form-group col-3">
			   					<div className="control-label ">Temperature </div>
			   					<div className="input-group">
										<div className="input-group-prepend">
											<span className="input-group-text">&#8451;</span>
										</div>
			   						<input type="text" className="form-control" maxLength="10" name="temperature" value={ this.state.shipment.temperature } onChange = { (e)=> this.shipmentHandleChange(e) }></input>
			   					</div>
			   				</div>
							)}
						</div>
						<div className="row col-12">
						<div className="form-group col-6 col-md-2">
							<div className="control-label">Customs clearance</div>
							<div className="">
								<div className="toggle-switch toggle-switch-primary" style={{marginTop:"5px"}}>
								 <label>
									 <input type="checkbox" className="form-check-input" name="customs_clearance" checked={this.state.shipment.customs_clearance} onChange = { (e)=> this.shipmentHandleChange(e) } />
									 <div className="toggle-switch-inner yesno"></div>
									 <div className="toggle-switch-switch"><i className="fa fa-check"></i></div>
								 </label>
							 	</div>
							</div>
						</div>
						{(this.state.shipment.customs_clearance===true) && (
							<>
							<div className="form-group col-6 col-md-3">
								<div className="control-label ">Customs documents</div>
								<div className="">
									<select className="form-control" name="customs_documents" value={ this.state.shipment.customs_documents } onChange = { (e)=> this.shipmentHandleChange(e) }>
										<option value="">Please select</option>
										<option value="T1">T1</option>
										<option value="T2">T2</option>
										<option value="TF">TF</option>
										<option value="EX1">EX1</option>
										<option value="TIR">TIR</option>
										<option value="ATA">ATA</option>
									</select>
								</div>
							</div>
							<div className="form-group col-6 col-md-3">
								<div className="control-label ">Customs broker</div>
								<div className="">
									<input className="form-control" name="customs_broker" maxLength="50" value={ this.state.shipment.customs_broker } onChange = { (e)=> this.shipmentHandleChange(e) }/>
								</div>
							</div>
							<div className="form-group col-6 col-md-3">
								<div className="control-label ">Customs costs</div>
								<div className="">
									<div className="input-group">
										<div className="input-group-prepend">
											<span className="input-group-text">€</span>
										</div>
										<input className="form-control" name="customs_costs" placeholder="0.00" maxLength="10" value={ this.state.shipment.customs_costs } onChange = { (e)=> this.shipmentHandleChange(e) }/>
									</div>

								</div>
							</div>
							</>
						)}
						</div>
						<div className="form-group">
							<div className="control-label col-12 col-md-3">Special instructions</div>
							<div className="col-sm-12 col-md-9">
								<textarea className="form-control" name="special_instructions" value={ this.state.shipment.special_instructions } onChange = { (e)=> this.shipmentHandleChange(e) }/>
							</div>
						</div>
						<h5>Internal notes/comments</h5>
						<hr/>
						{ this.state.user.role === "admin" ?
						<div className="form-group ">
							<div className="control-label col-12 col-md-3">Add new internal note/comment</div>
							<div className="col-9">
							<DatePicker className="form-control col-3"
								selected = { this.state.shipment.new_comment_date }
									onChange= { (val) => this.shipmentHandleChange({ target : { name : "new_comment_date", value : val } }) }
									dateFormat = "DD.MM.YYYY"
									placeholderText = "Click to select date"
							/>
							<textarea className="form-control" name="new_comment_text" value={ this.state.shipment.new_comment_text } onChange={ (e)=>this.shipmentHandleChange(e) }/>
							</div>
						</div>
						:null  }
						<div className={"form-group " + (this.state.isNewRecord ? 'd-none' : '') }>
							<div className="control-label col-12 col-md-3">Previous comments</div>
							<div className="col-9">
								<ul className="list-group mb-2">
									{ previous_comments }
								</ul>
								<NotesDownload job={ this.state.shipment.nl_ref } rows={this.state.shipment.comments}/>
							</div>

						</div>
						<h5>Dates</h5>
						<hr/>
	   				<div className="row col-12">
		   				<div className="form-group col-12 col-md-4 col-lg-3 " >
		   					<div className="control-label">Pickup date</div>
		   					<div className="">
								<DatePicker className="form-control"
									selected = { this.state.shipment.pickup_date }
								    onChange= { (val) => this.shipmentHandleChange({ target : { name : "pickup_date", value : val } }) }
								    dateFormat = "DD.MM.YYYY"
								     utcOffset={0}
								    placeholderText = "Click to select date"

								/>
		   					</div>
		   				</div>
		   				<div className="form-group col-12 col-md-4 col-lg-3 ">
		   					<div className="control-label">Posit date</div>
		   					<div className="">
								<DatePicker className="form-control"
									selected = { this.state.shipment.posit_date }
								    onChange= { (val) => this.shipmentHandleChange({ target : { name : "posit_date", value : val } }) }
								    dateFormat = "DD.MM.YYYY"
										utcOffset={0}
								    placeholderText = "Click to select date"

								/>
		   					</div>
		   				</div>
							{this.state.shipment.kind!=="FTL"&&this.state.shipment.kind!=="LTL" && (
							<div className="form-group col-12 col-md-4 col-lg-3 ">
		   					<div className="control-label">Dropoff date & time</div>
		   					<div className="">
								<DatePicker className="form-control"
									selected = { this.state.shipment.dropoff_date }
								    onChange= { (val) => this.shipmentHandleChange({ target : { name : "dropoff_date", value : val } }) }
								    dateFormat = "DD.MM.YYYY HH:mm"
								     utcOffset={0}
								    placeholderText = "Click to select date"
										showTimeSelect
										timeFormat="HH:mm"
										timeIntervals={15}
								/>
		   					</div>
		   				</div>
							)}
							{(this.state.shipment.customs_clearance===true) && (
							<div className="form-group col-12 col-md-4 col-lg-3 ">
								<div className="control-label">Customs date & time</div>
								<div className="">
								<DatePicker className="form-control"
									selected = { this.state.shipment.customs_date }
										onChange= { (val) => this.shipmentHandleChange({ target : { name : "customs_date", value : val } }) }
										dateFormat = "DD.MM.YYYY HH:mm"
										 utcOffset={0}
										placeholderText = "Click to select date"
										showTimeSelect
										timeFormat="HH:mm"
										timeIntervals={15}
								/>
								</div>
							</div>
						)}
		   				<div className={"form-group col-12 col-md-4 col-lg-3 " + (this.state.isNewRecord ? 'd-none' : '') }>
		   					<div className="control-label">Record created date</div>
		   					<div className="">
								<DatePicker className="form-control"
									selected = { this.state.shipment.created }
								    disabled
								    dateFormat = "DD.MM.YYYY"
								     utcOffset={0}
								    placeholderText = "Click to select date"
								/>
		   					</div>
		   				</div>
	   				</div>

						<h5>Addresses</h5>
						<hr/>
						<div className="row">
	   				<div className="col-sm-12 col-md-6 mt-1">
	   					<div className="card">
		   					<h5 className="card-header">
		   					(1) Pickup
		   					</h5>
		   					<div className="card-body">
				   				<div className="form-group">
				   					<div className="control-label col-sm-12 col-md-3" id="pickup">Company</div>
				   					<div className="col-sm-12 col-md-9">
				   						<div className="row">
				   						<div className="col-sm-12 col-md-10">
				   						<input type="text" className="form-control" name="pickup_name" value={ this.state.shipment.pickup_name } onChange = { (e)=> this.shipmentHandleChange(e) }/>
				   					</div>
				   					<div className="col-2 btn-group">
				   						<button className="btn btn-primary" type="button" name="supplier" onClick={ ()=> this.openAddressBook("pickup") } ><i className="fa fa-address-book"  /> <span className="d-none d-sm-inline-block">Open</span></button>
				   						<button className="btn btn-success" type="button" name="supplier" onClick={ ()=>this.addToAddressBook("pickup") }><i className="fa fa-plus" /> <span className="d-none d-sm-inline-block">Add</span></button>
				   					</div>
				   					</div>
				   					</div>
				   				</div>
				   				<div className="form-group">
				   					<div className="control-label col-sm-12 col-md-4">Address</div>
				   					<div className="col-sm-12 col-md-9">
											<input type="text" className="form-control" placeholder="Street" name="pickup_street" value={ this.state.shipment.pickup_street } onChange = { (e)=> this.shipmentHandleChange(e) }/>
											<input type="text" className="form-control" placeholder="City" name="pickup_city" value={ this.state.shipment.pickup_city } onChange = { (e)=> this.shipmentHandleChange(e) }/>
											
											<Select placeholder="Country" name="pickup_country" value={ countries.find((c)=> this.state.shipment.pickup_country ===c.value) } onChange = { (e)=> this.shipmentHandleChange({target:{name:"pickup_country",value:e.value}}) }
												options={countries}
											/> 
											
											<input type="text" className="form-control" placeholder="Post code" maxLength="15" name="pickup_zip" value={ this.state.shipment.pickup_zip } onChange = { (e)=> this.shipmentHandleChange(e) }/>
				   					</div>
				   				</div>
				   				<div className="form-group">
				   					<div className="control-label col-sm-12 col-md-4">Contact Person</div>
				   					<div className="col-sm-12 col-md-9">
											<input type="text" className="form-control" placeholder="Name, tel, email" name="pickup_contact" value={ this.state.shipment.pickup_contact } onChange = { (e)=> this.shipmentHandleChange(e) }/>
				   					</div>
				   				</div>
				   				<div className="form-group">
				   					<div className="control-label col-sm-12 col-md-4">Opening hours</div>
				   					<div className="col-sm-12 col-md-9">
											<input type="text" className="form-control" placeholder="" name="pickup_opening_hours" value={ this.state.shipment.pickup_opening_hours } onChange = { (e)=> this.shipmentHandleChange(e) }/>
				   					</div>
				   				</div>
									<div className="form-group">
										<div className="control-label col-3">Pickup reference</div>
										<div className="col-sm-12 col-md-9">
											<input type="text" className="form-control" value={ this.state.shipment.collection_reference } maxLength="40" name="collection_reference" onChange= { (e)=> this.shipmentHandleChange(e) } ></input>
										</div>
									</div>
			   				</div>
	   					</div>
	   				</div>

	   				<div className="col-sm-12 col-md-6 mt-1 mb-1">
	   					<div className="card">
		   					<h5 className="card-header">
		   					(2) Posit
		   					</h5>
		   					<div className="card-body">
				   				<div className="form-group">
				   					<div className="control-label col-sm-12 col-md-3" id="posit">Company</div>
				   					<div className="col-sm-12 col-md-9">
				   						<div className="row">
				   						<div className="col-sm-12 col-md-10">
				   						<input type="text" className="form-control" name="delivery_name" value={ this.state.shipment.delivery_name } onChange = { (e)=> this.shipmentHandleChange(e) }/>
				   						</div>
				   						<div className="col-2 btn-group">
				   						<button className="btn btn-primary" type="button" name="posit" onClick={ ()=> this.openAddressBook("posit") }><i className="fa fa-address-book" /> <span className="d-none d-sm-inline-block">Open</span></button>
				   						<button className="btn btn-success" type="button"  name="posit" onClick={ ()=>this.addToAddressBook("posit") }><i className="fa fa-plus" /> <span className="d-none d-sm-inline-block">Add</span></button>
				   						</div>
				   						</div>
				   					</div>
				   				</div>
				   				<div className="form-group">
				   					<div className="control-label col-sm-12 col-md-4">Address</div>
				   					<div className="col-sm-12 col-md-9">
											<input type="text" className="form-control" placeholder="Street" name="delivery_street" value={ this.state.shipment.delivery_street } onChange = { (e)=> this.shipmentHandleChange(e) }/>
											<input type="text" className="form-control" placeholder="City" name="delivery_city" value={ this.state.shipment.delivery_city } onChange = { (e)=> this.shipmentHandleChange(e) }/>
				
											<Select placeholder="Country" name="delivery_country" value={ countries.find((c)=> this.state.shipment.delivery_country ===c.value) } onChange = { (e)=> this.shipmentHandleChange({target:{name:"delivery_country",value:e.value}}) }
												options={countries}
											/> 
											<input type="text" className="form-control" placeholder="Post code" maxLength="15" name="delivery_zip" value={ this.state.shipment.delivery_zip } onChange = { (e)=> this.shipmentHandleChange(e) }/>
				   					</div>
				   				</div>
				   				<div className="form-group">
				   					<div className="control-label col-sm-12 col-md-43">Contact person</div>
				   					<div className="col-sm-12 col-md-9">
											<input type="text" className="form-control" placeholder="Name, tel, email" name="delivery_contact" value={ this.state.shipment.delivery_contact } onChange = { (e)=> this.shipmentHandleChange(e) }/>
				   					</div>
				   				</div>
				   				<div className="form-group">
				   					<div className="control-label col-sm-12 col-md-4">Opening hours</div>
				   					<div className="col-sm-12 col-md-9">
											<input type="text" className="form-control" placeholder="" name="delivery_opening_hours" value={ this.state.shipment.delivery_opening_hours } onChange = { (e)=> this.shipmentHandleChange(e) }/>
				   					</div>
				   				</div>
									<div className="form-group">
										<div className="control-label col-3">Posit reference</div>
										<div className="col-sm-12 col-md-9">
											<input type="text" className="form-control" value={ this.state.shipment.delivery_reference } name="delivery_reference" maxLength="40" onChange= { (e)=> this.shipmentHandleChange(e) } ></input>
										</div>
									</div>
			   				</div>
		   				</div>
		   			</div>
						{/*(this.state.shipment.kind!=="FTL"&&this.state.shipment.kind!=="LTL") && (
						<div className="col-sm-12 col-md-6 mt-1 mb-1">
						  <div className="card">
						    <h5 className="card-header">
						    (3) Dropoff
						    </h5>
						    <div className="card-body">
						      <div className="form-group">
						        <div className="control-label col-sm-12 col-md-3" id="dropoff">Company</div>
						        <div className="col-sm-12 col-md-9">
						          <div className="row">
						          <div className="col-sm-12 col-md-10">
						          <input type="text" className="form-control" name="dropoff_name" value={ this.state.shipment.dropoff_name } onChange = { (e)=> this.shipmentHandleChange(e) }/>
						          </div>
						          <div className="col-2 btn-group">
						          <button className="btn btn-primary" type="button" name="dropoff" onClick={ ()=> this.openAddressBook("dropoff") }><i className="fa fa-address-book" /> <span className="d-none d-sm-inline-block">Open</span></button>
						          <button className="btn btn-success" type="button"  name="dropoff" onClick={ ()=>this.addToAddressBook("dropoff") }><i className="fa fa-plus" /> <span className="d-none d-sm-inline-block">Add</span></button>
						          </div>
						          </div>
						        </div>
						      </div>
						      <div className="form-group">
						        <div className="control-label col-sm-12 col-md-4">Address</div>
						        <div className="col-sm-12 col-md-9">
						          <input type="text" className="form-control" placeholder="Street" name="dropoff_street" value={ this.state.shipment.dropoff_street } onChange = { (e)=> this.shipmentHandleChange(e) }/>
						          <input type="text" className="form-control" placeholder="City" name="dropoff_city" value={ this.state.shipment.dropoff_city } onChange = { (e)=> this.shipmentHandleChange(e) }/>
						          
								  <Select placeholder="Country" name="dropoff_country" value={ countries.find((c)=> this.state.shipment.dropoff_country ===c.value) } onChange = { (e)=> this.shipmentHandleChange({target:{name:"dropoff_country",value:e.value}}) }
												options={countries}
											/> 
									
						          <input type="text" className="form-control" placeholder="Post code" maxLength="15" name="dropoff_zip" value={ this.state.shipment.dropoff_zip } onChange = { (e)=> this.shipmentHandleChange(e) }/>
						        </div>
						      </div>
						      <div className="form-group">
						        <div className="control-label col-sm-12 col-md-43">Contact person</div>
						        <div className="col-sm-12 col-md-9">
						          <input type="text" className="form-control" placeholder="Name, tel, email" name="dropoff_contact" value={ this.state.shipment.dropoff_contact } onChange = { (e)=> this.shipmentHandleChange(e) }/>
						        </div>
						      </div>
						      <div className="form-group">
						        <div className="control-label col-sm-12 col-md-4">Opening hours</div>
						        <div className="col-sm-12 col-md-9">
						          <input type="text" className="form-control" placeholder="" name="dropoff_opening_hours" value={ this.state.shipment.dropoff_opening_hours } onChange = { (e)=> this.shipmentHandleChange(e) }/>
						        </div>
						      </div>
									<div className="form-group">
										<div className="control-label col-3">Dropoff reference</div>
										<div className="col-sm-12 col-md-9">
											<input type="text" className="form-control" value={ this.state.shipment.dropoff_reference } name="dropoff_reference" maxLength="40" onChange= { (e)=> this.shipmentHandleChange(e) } ></input>
										</div>
									</div>
						    </div>
						  </div>
						</div>
						)*/}
						{(this.state.shipment.customs_clearance===true) && (
						<div className="col-sm-12 col-md-6 mt-1 mb-1">
						 <div className="card">
							 <h5 className="card-header">
							 (4) Customs stop
							 </h5>
							 <div className="card-body">
							   <div className="form-group">
							     <div className="control-label col-sm-12 col-md-3" id="customs">Company</div>
							     <div className="col-sm-12 col-md-9">
							       <div className="row">
							       <div className="col-sm-12 col-md-10">
							       <input type="text" className="form-control" name="customs_name" value={ this.state.shipment.customs_name } onChange = { (e)=> this.shipmentHandleChange(e) }/>
							       </div>
							       <div className="col-2 btn-group">
							       <button className="btn btn-primary" type="button" name="customs" onClick={ ()=> this.openAddressBook("customs") }><i className="fa fa-address-book" /> <span className="d-none d-sm-inline-block">Open</span></button>
							       <button className="btn btn-success" type="button"  name="customs" onClick={ ()=>this.addToAddressBook("customs") }><i className="fa fa-plus" /> <span className="d-none d-sm-inline-block">Add</span></button>
							       </div>
							       </div>
							     </div>
							   </div>
							   <div className="form-group">
							     <div className="control-label col-sm-12 col-md-4">Address</div>
							     <div className="col-sm-12 col-md-9">
							       <input type="text" className="form-control" placeholder="Street" name="customs_street" value={ this.state.shipment.customs_street } onChange = { (e)=> this.shipmentHandleChange(e) }/>
							       <input type="text" className="form-control" placeholder="City" name="customs_city" value={ this.state.shipment.customs_city } onChange = { (e)=> this.shipmentHandleChange(e) }/>
							       <input type="text" className="form-control" placeholder="Country" maxLength="100" name="customs_country" value={ this.state.shipment.customs_country } onChange = { (e)=> this.shipmentHandleChange(e) }/>
							       <input type="text" className="form-control" placeholder="Post code" maxLength="15" name="customs_zip" value={ this.state.shipment.customs_zip } onChange = { (e)=> this.shipmentHandleChange(e) }/>
							     </div>
							   </div>
							   <div className="form-group">
							     <div className="control-label col-sm-12 col-md-43">Contact person</div>
							     <div className="col-sm-12 col-md-9">
							       <input type="text" className="form-control" placeholder="Name, tel, email" name="customs_contact" value={ this.state.shipment.customs_contact } onChange = { (e)=> this.shipmentHandleChange(e) }/>
							     </div>
							   </div>
							   <div className="form-group">
							     <div className="control-label col-sm-12 col-md-4">Opening hours</div>
							     <div className="col-sm-12 col-md-9">
							       <input type="text" className="form-control" placeholder="" name="customs_opening_hours" value={ this.state.shipment.customs_opening_hours } onChange = { (e)=> this.shipmentHandleChange(e) }/>
							     </div>
							   </div>
							 </div>
							 </div>

					 </div>
					 )}
					 </div>

						<h5>Attachments</h5>
						<hr/>
	   				<div className="form-group col-12 " >
	   					<div className="col-12">
	   						<div className="row fileslist">
	   						{ this.state.shipment.filesnames.map((file)=>
									<div className="list-group-item col-12 col-sm-6 col-md-3">
									{this.state.user.role==="admin" &&(
									<i className="fa fa-trash" title="Delete attachment" onClick={()=>this.deleteAttachment(file[0])} />
									)}
									<button type="button" className="btn btn-secondary" onClick={ ()=> this.downloadFile(file[0]) }><span className="fa fa-download"></span></button>  { file[1] }</div>
									)
	   						 }
	   						</div>
          <Dropzone onDrop={this.handleFileUpload.bind(this)} className="dropzone mt-1"  activeClassName="dropzoneActive">
            <p>Drop new files here, or click to select files to upload.</p>
          </Dropzone>
          					{this.state.shipment.files.length > 0 ? <p className="mt-2">Files to upload</p> : ''}
	   						 <ul className="fileUploadList">{ this.state.shipment.files.map(f => <li key={f.name} className="list-group-item"><i className="fa fa-trash" title="Delete attachment" onClick={()=>this.deleteAttachment(f.name,true)} /> {f.name}</li> )}</ul>
	   					</div>
	   				</div>
	   				<div className="form-group">
	   					<div className="btn-group col-12 col-lg-6">
								{ this.state.isEditRecord===true  && (
									<button type="button" className="btn btn-success col-6 col-md-3" onClick={ this.updateRecord }>Update</button>
								)}
									{ this.state.isEditRecord===true && this.state.user.canCreateRecord === "t" && (
  										<button type="button" className="btn btn-success col-6 col-md-3" onClick={()=>this.switchTab("new") }>Create another record </button>
  								 	)}

  							{ this.state.isNewRecord===true  && (
  								this.state.user.canCreateRecord && (
  								<button type="button" className="btn btn-success col-6 col-md-3" onClick={ this.createRecord }>Create new record</button>
  								)
  	 						)}
								<button type="button" className="btn btn-primary col-6 col-md-3" onClick={this.switchTabDva}><i className="fa fa-undo"/> Back</button>

							</div>
	   				</div>
					</form>
				</div>
				<div id="addressbook" className={ this.state.AddressbookContainer } style={{"zIndex":1000}}>
				<h3><i className="fa fa-address-book"/> Address Book </h3>
					<button type="button" className="btn btn-primary" onClick={ ()=>{
						if(this.insertElement.startsWith("quote")){
							this.setState({ AddressbookContainer : "d-none",quotesListContainer : "col-12" })
						}else {
							this.setState({ AddressbookContainer : "d-none",detailContainer : "col-12" })
						}
						}}><i className="fa fa-undo"/> Back</button>
				  <ReactTable
				  	 className="-highlight"
				    data={this.state.addressBookDataVisible}
				    columns={addressBookColumns}
				  />
				</div>
      	</div>
      	</div>
		{/*
				<div className={ this.state.quotesListContainer } style={{"display":this.state.AddressbookContainer==="col-12" ? "none" : ""}}>
					{this.state.quotesListContainer === "col-12" && (
						<Quotes
							url = {url}
							signout = { this.signout }
							createNotification = {this.createNotification}
							openAddressBook = {this.openAddressBook}
							addToAddressBook = {this.addToAddressBook}
							quoteFormRef = { this.quoteFormRef }
							createShipment = {this.createShipment}
						/>
						)}
				</div>
		*/}
				{this.state.dgn_visible === true &&(
					<DGNForm hideDGNForm={this.hideDGNForm} url={url} createNotification={this.createNotification}/>
				)}

			<NotificationContainer/>

			<div id="end"></div>
			{this.state.displayClientModal===true &&(
			<ClientModal
				show={this.state.displayClientModal}
				files={this.state.shipment.filesnames}
				hideClientModal={this.hideClientModal}
				shipment={this.state.shipment}
				url={url}
				createNotification={this.createNotification}/>
			)}
			{this.state.displayCarrierModal===true &&(
			<CarrierModal
				show={this.state.displayCarrierModal}
				files={this.state.shipment.filesnames}
				hideCarrierModal={this.hideCarrierModal}
				shipment={this.state.shipment}
				url={url}
				createNotification={this.createNotification}/>
			)}
			{this.state.displayUpdateModal===true &&(
			<NotifyModal
				updateRecord={this.updateRecord}
				show={this.state.displayUpdateModal}
				hideModal={this.hideUpdateModal}
				shipment={this.state.shipment}
				shipmentHandleChange = {this.shipmentHandleChange}
				/>
			)}
			{this.state.displayEmailModal===true &&(
			<EmailModal
				updateRecord={this.updateRecord}
				show={this.state.displayEmailModal}
				hideModal={this.hideUpdateModal}
				to = {this.state.emailModalTo}
				shipment = {this.state.shipment}
				hideEmailModal = { this.hideEmailModal }
				url = {url}
				createNotification={this.createNotification}
				/>
			)}
      </div>
    );
  }
}
export default App;
