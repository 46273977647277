import React, { Component } from 'react';
import moment from 'moment';
import JSAlert from 'js-alert';
import {containers} from "../settings";
import DatePicker from 'react-datepicker';
import ReactTooltip from 'react-tooltip';
const emptyQuote = {
  recnum : null,
  reference:"",
  quotation_number : "",
  client_name : "",
  client_email:"",
  client_reference:"",
  dimensions : [],
  carriers:[],
  pickup_name:"",
  pickup_city:"",
  pickup_street:"",
  pickup_country:"",
  pickup_zip:"",
  pickup_contact:"",
  pickup_opening_hours:"",
  delivery_street:"",
  delivery_city:"",
  delivery_country:"",
  delivery_zip:"",
  delivery_contact:"",
  delivery_opening_hours:"",
  delivery_name:"",
  special_agreements:"",
  customs_clearance : false,
  danger_goods : false,
  kind : "",
  status : "waiting",
  dropoff_street:"",
  dropoff_city:"",
  dropoff_country:"",
  dropoff_zip:"",
  dropoff_contact:"",
  dropoff_opening_hours:"",
  dropoff_name:"",
  customs_street:"",
  customs_city:"",
  customs_country:"",
  customs_zip:"",
  customs_contact:"",
  customs_opening_hours:"",
  customs_name:"",
  additional_costs:[],
  sales_rate_validity : "",
  sales_rate : "0.00",
  temperature_required : false,
  temperature:'',
  special_instructions : "",
  fuel_included: false,
  changed : false,
  direction : "",
  sent_to_client : null,
}
export default class QuotesOverview extends Component {
  constructor(props) {
    super(props);
    this.url = props.url;
    this.switchView=props.switchView;
    this.recordNum = props.recordNum;
    this.signout = props.signout;
    this.createNotification = props.createNotification;
    this.addressBookInsertCarrierIndex = null;
    this.openAddressBook = (el,index)=>{
      if(index!==undefined){
        this.addressBookInsertCarrierIndex=index;
      }
      props.openAddressBook(el);
    }
    this.createShipment = props.createShipment;
    this.quoteTableRef = props.quoteTableRef;

    this.dragCounter=0;
    this.addToAddressBook = (a,carrier)=>{
      let what = this.state.formData;
      var o = {};
      o[a+"_name"]=what[a+"_name"];
      o[a+"_street"]=what[a+"_street"] === undefined ? "":what[a+"_street"];
      o[a+"_city"]=what[a+"_city"]===undefined ? "":what[a+"_city"];
      o[a+"_country"]=what[a+"_country"] === undefined ? "":what[a+"_country"];
      o[a+"_zip"]=what[a+"_zip"] === undefined ? "":what[a+"_zip"];
      o[a+"_contact"]=what[a+"_contact"] === undefined ? what[a+"_email"]:what[a+"_contact"];
      o[a+"_opening_hours"]=what[a+"_opening_hours"]===undefined ? "":what[a+"_opening_hours"];
      if(a==="carrier"){
        o[a+"_name"]=carrier.name;
        o[a+"_contact"]=carrier.emails;
      }
      props.addToAddressBook("quote_"+a,o);
    }
    this.insertAddress = (what,where)=> {
      let a  = where.slice(6);
      if(a==="posit")a="delivery";

      if(where==="quote_carrier"){
        let carriers = this.state.formData.carriers;
        carriers[this.addressBookInsertCarrierIndex].name=what.name;
        carriers[this.addressBookInsertCarrierIndex].emails=what.contact;
        this.setState( prevState =>({
          formData : {
            ...prevState.formData,
            carriers : carriers
          }
        }))
        this.addressBookInsertCarrierIndex=null;
      }else {
        var o = {};
        o[a+"_name"]=what.name;
        o[a+"_street"]=what.street;
        o[a+"_city"]=what.city;
        o[a+"_country"]=what.country;
        o[a+"_zip"]=what.zip;
        o[a+"_email"]=what.contact;
        o[a+"_opening_hours"]=what.opening_hours;
        this.setState( prevState =>({
          formData : {
            ...prevState.formData,
            ...o
          }
        }))
      }
    }
    this.state={
      formData : emptyQuote,
      selected_packing : "Pallet",
      isNew : true,
      displayEmailModal : false
    }
  }
  componentDidMount(){
    if(this.recordNum!=="new"){
      this.fetchRecord(this.recordNum);
    }
  }

  fetchRecord = (recnum) =>{
    var self=this;
		fetch(this.url+"api2.php?data", {
		method: 'POST',
		credentials: 'include',
    body : JSON.stringify({action:"fetchQuote",recnum:recnum})
  		 })
		 .then((response) => response.json())
		 .then(
        (res) => {
        		if (res.status==="error"){
        			if (res.message==="Please login") {
        				JSAlert.alert("Session closed, please sign in again",null,JSAlert.Icons.Failed).then(()=>
                  this.signout()
				   	    )
        			}
        			else JSAlert.alert(res.message,null,JSAlert.Icons.Failed);
        			return;
        		}
        		var data = res;
        		if (data===false) {
        			JSAlert.alert("Unable to open quote, record doesn't exist.",null,JSAlert.Icons.Failed)
        			return false;
        		}
            data.dimensions = JSON.parse(data.dimensions);
            data.carriers = JSON.parse(data.carriers);
            data.additional_costs = JSON.parse(data.additional_costs);
            data.customs_clearance = data.customs_clearance === "t" ? true : false;
            data.temperature_required = data.temperature_required === "t" ? true : false;
            data.fuel_included = data.fuel_included === "t" ? true : false;
            console.log(data);
            if(data.sales_rate_validity!==null)data.sales_rate_validity=moment(data.sales_rate_validity)
				    this.setState({ formData : { ...emptyQuote, ...data },isNew:false});

        }).catch((error) => {
         	if(error)JSAlert.alert(error,null,JSAlert.Icons.Failed)
        });
	}
  quoteHandleChange(e){
    let name = e.target.name;
    let val = e.target.value;
    if (e.target.type==="checkbox") {
			val = e.target.checked;
		}
    switch (name) {
      case "sales_rate":
          val = val.replace(/[^0-9\.]+/g,"");
        break;
      default:

    }
    this.setState( prevState =>({
      formData : {
        ...prevState.formData,
        [name]:val,
        changed : true
      }
    }))
  }
  deleteRecord = () =>{
  		var self = this;
  		JSAlert.confirm("Are you sure you want to delete this record ?",null, JSAlert.Icons.Deleted).then(function(result) {
      if (!result)
          return;
          var o = {
  			  action:'deleteQuote',
  		    recnum: self.state.formData.recnum
        }
  	fetch(self.url+"api2.php?data" , {
  	 method: 'POST',
      mode: 'cors',
      cache: 'default',
  	 credentials : 'include',

  		body : JSON.stringify(o),
    		 })
  		 .then((response) => response.json())
  		 .then(
          (res) => {
  				self.createNotification(res.status,res.message);
  				if (res.status==="success") {
  					self.switchView("table");
  				}
          }).catch((error) => {
           	if(error)JSAlert.alert(error,null,JSAlert.Icons.Failed)
          });
       })
  }
  addDimensionsRow = ()=>{
    var formData = {...this.state.formData};

    if(this.state.selected_packing==="Container"){
      this.setState( prevState =>({
        formData : {
          ...prevState.formData,
          changed:true,
          dimensions: [...prevState.formData.dimensions,{
            rowId : prevState.formData.dimensions.length,packing : this.state.selected_packing,
            length:'20"',
            number:'',
            tare : containers.find((r)=>{return r.type==='20"'}).weight,
            seanumber:'',
            carrier:'',
            weight:''
          } ],
          pieces : Number(prevState.formData.pieces)+1,
          total_weight : Number(prevState.formData.total_weight)+Number(containers.find((r)=>{return r.type==='20"'}).weight)
        }
      }))
    }else {
      this.setState( prevState =>({
        formData : {
          ...prevState.formData,
          dimensions: [...prevState.formData.dimensions,{ rowId : prevState.formData.dimensions.length,packing : this.state.selected_packing,width:'',height:'',pieces:'',weight:'' } ]
        }
      }))
    }
  }
  delDimensionsRow(rowId){
    var formData = {...this.state.formData};
    var counter = 0;
    var rows = this.state.formData.dimensions.filter((e)=>{
      if(e.rowId !== rowId){
        e.rowId = counter;
        counter++;
        return e;
      }
    })
    var total_weight = 0;
    var pieces = 0;
    rows.forEach((row)=>{

      if(row.packing==="Container"){
        pieces++;
        total_weight+= Number(row.weight) + Number(row.tare);
      }else {
        total_weight+= Number(row.weight) * Number(row.pieces);
        pieces += Number(row.pieces);
      }
    });
    this.setState( prevState =>({
      formData : {
        ...prevState.formData,
        changed:true,
        dimensions: rows,
        total_weight : total_weight,
        pieces:pieces
      }
    }) )
  }

  addCarrier=()=>{
    var carriers = [...this.state.formData.carriers];
    carriers.push({
      name : "",
      emails : "",
      rate : "",
      validity:"",
      sent:false,
      date:""
    })
    this.setState( prevState =>({
      formData : {
        ...prevState.formData,
        changed:true,
        carriers : carriers
      }
    }) )
  }
  updateCarrier = (e,i)=>{
    var carriers = [...this.state.formData.carriers];
    carriers[i][e.target.name]=e.target.value;
    this.setState( prevState =>({
      formData : {
        ...prevState.formData,
        changed:true,
        carriers : carriers
      }
    }) )
  }
  removeCarrier = (index)=>{
    var carriers = [...this.state.formData.carriers];
    carriers.splice(index, 1);
    this.setState( prevState =>({
      formData : {
        ...prevState.formData,
        changed:true,
        carriers : carriers
      }
    }))
  }
  updateDimensionRow(e,rowId){
    var total_weight = 0;
    var pieces = 0;
    var rows = this.state.formData.dimensions.filter((row)=>{
      if(row.rowId === rowId){
        row[e.target.name]= e.target.value;
        if(row.packing==="Container"){
          pieces++;
          if(e.target.name==="length"){
            let tw = containers.find((r)=>{ return r.type===row.length});
            row.tare = tw!==undefined ? tw.weight :0;
          }
          total_weight+= Number(row.weight)+Number(row.tare);
        }else {
          total_weight+= Number(row.weight) * Number(row.pieces);
          pieces += Number(row.pieces);
        }
        return row;
      }
      else {
        if(row.packing==="Container"){
          pieces++;
          total_weight+= Number(row.weight)+Number(row.tare);
        }else {
          total_weight+=Number(row.weight) * Number(row.pieces);
          pieces += Number(row.pieces);
        }
        return row;
      }

    })

    this.setState( prevState =>({
      formData : {
        ...prevState.formData,
        changed:true,
        dimensions: rows,
      }
    }) )
  }
  saveRecord = (action)=>{
  		let data = {...this.state.formData};
      data.action = action;
  		var self = this;

  	fetch(this.url+"api2.php?data" , {
  	  method: 'POST',
      mode: 'cors',
      cache: 'default',
  	  credentials : 'include',
  		body : JSON.stringify(data),
    		 })
  		 .then((response) => response.json())
  		 .then(
          (res) => {
  				this.createNotification(res.status,res.message);
  				if (res.status==="success") {
            this.setState( prevState =>({
              isNew:false,
              formData : {
                ...prevState.formData,
                changed:false,
                carriers:res.carriers
              }
            }),()=>{
              if(action==="saveQuote"){
                this.setState( prevState =>({
                  formData : {
                    ...prevState.formData,
                    recnum : res.recnum
                  }
                }))
              }
            })
  				}
          		if (res.status==="error"){
          			if (res.message==="Please login") {
          				JSAlert.alert("Session closed, please sign in again",null,JSAlert.Icons.Failed).then(()=>
                    this.signout()
  				   	     )
          			}
          			else JSAlert.alert(res.message,null,JSAlert.Icons.Failed);
          			return;
          		}
          }).catch((error) => {
           	if(error)JSAlert.alert(error,null,JSAlert.Icons.Failed)
          });
  }
  sendQuoteCarriers = ()=>{
    if(this.state.formData.changed===true){
      return this.createNotification("warning","In quote are unsaved changes, save them first.");
    }
    JSAlert.confirm("This action will send an email with rate request to all provided carrier's emails. Please confirm","Rate request",JSAlert.Icons.Info).then((res)=>{
      if(!res)return
      if(this.state.formData.carriers.length===0){
        JSAlert.alert("There are no carriers specified",null,JSAlert.Icons.Failed)
      }else {
        fetch(this.url+"api2.php?data" , {
      	  method: 'POST',
          mode: 'cors',
          cache: 'default',
      	  credentials : 'include',
      		body : JSON.stringify({
            action : "carrierRateRequest",
            quote : this.state.formData
          }),
        		 })
      		 .then((response) => response.json())
      		 .then(
              (res) => {
              		if (res.status==="error"){
              			if (res.message==="Please login") {
              				JSAlert.alert("Session closed, please sign in again",null,JSAlert.Icons.Failed).then(()=>
                        this.signout()
      				   	     )
              			}
              		}
                  else {
                    res.statuses.forEach((item)=>{
                      this.createNotification(item.status,item.message);
                    })
                    this.setState( prevState =>({
                      formData : {
                        ...prevState.formData,
                        carriers : res.carriers
                      }
                    }) )
                    this.createNotification(res.result.status,res.result.msg);
                  }

              }).catch((error) => {
               	if(error)JSAlert.alert(error,null,JSAlert.Icons.Failed)
              });
      }
    })
  }
  sendQuoteOneCarrier = (carrier) =>{
    if(this.state.formData.changed===true){
      return this.createNotification("warning","In quote are unsaved changes, save them first.");
    }
    JSAlert.confirm("This action will send an email with rate request to this carrier. Please confirm","Rate request",JSAlert.Icons.Info).then((res)=>{
      if(!res)return
      let emails = carrier.emails.split(",");
      let error = false;
      emails.forEach((email)=>{
        if(this.validateEmail(email)===false){
          error=true;
        }
      })
      if(error===true){
        JSAlert.alert("Entered emails are not in valid format.",null,JSAlert.Icons.Failed)
      }else {

        fetch(this.url+"api2.php?data" , {
      	  method: 'POST',
          mode: 'cors',
          cache: 'default',
      	  credentials : 'include',
      		body : JSON.stringify({
            action : "oneCarrierRateRequest",
            quote : this.state.formData,
            carrier : carrier
          }),
        		 })
      		 .then((response) => response.json())
      		 .then(
              (res) => {
              		if (res.status==="error"){
              			if (res.message==="Please login") {
              				JSAlert.alert("Session closed, please sign in again",null,JSAlert.Icons.Failed).then(()=>
                        this.signout()
      				   	     )
              			}
                    else JSAlert.alert(res.message,null,JSAlert.Icons.Failed);
                    return;
              		}
                  else {
                    this.setState( prevState =>({
                      formData : {
                        ...prevState.formData,
                        carriers : res.carriers
                      }
                    }) )
                  }

              }).catch((error) => {
               	if(error)JSAlert.alert(error,null,JSAlert.Icons.Failed)
              });
        }
    })
  }
  handleFileUpload( event,carrierIndex ) {

    document.querySelectorAll(".dropzoneActive").forEach((el)=>{
      el.classList.remove("dropzoneActive");
    })
    this.dragCounter=0;

      var dataTransferItemsList = [];
      if (event.dataTransfer) {
        var dt = event.dataTransfer;
        if (dt.files && dt.files.length) {
          dataTransferItemsList = dt.files;
        } else if (dt.items && dt.items.length) {
          dataTransferItemsList = dt.items;
        }
      } else if (event.target && event.target.files) {
        dataTransferItemsList = event.target.files;
      }
      let files = Array.prototype.slice.call(dataTransferItemsList);
   var self = this;
 //	files = files.target.files;
   var count = files.length;
     if (files.length>0) {
       var a = [];
       //Object.assign( a,this.state.shipment.files);
       for(var i=0;i<count;i++){
          // create reader
         var reader = new FileReader();
         reader.readAsDataURL(files[i]);
         reader.onload = (function(file) {
         var filename = file.name;
         var mimetype = file.type;
         var ext = filename.split(".").pop();
         return async function (e) {
         var contents = e.target.result.split("base64,").pop();
           a.push({name:filename, mimetype: mimetype, source: contents, ext: ext });
           count--;
           if (count===0) {
             let carriers = self.state.formData.carriers;
             carriers[carrierIndex].newFiles = a;
             self.setState({ formData : { ...self.state.formData,carriers:carriers }});
             self.createNotification("info","Files are ready to upload" );
           }
         }})(files[i]);
         }
     }


 }
  createOrder = (carrier) =>{
    let data = {...this.state.formData};
    delete data.carriers;
    delete data.created;
    delete data.recnum;
    data.quotation_number = data.reference;
    delete data.reference;
    data.carrier_name = carrier.name;
    data.price = carrier.rate;
    data.carrier_emails=carrier.emails;
    delete data.client_reference;
    if(carrier.files!==undefined&&carrier.files!==null){
      data.files = carrier.files;
    }else {
      data.files=[];
    }
    this.createShipment(data)
  }
  addCostsRow = () =>{
    let costs = this.state.formData.additional_costs;
    costs.push({text:"",val:""})
    this.setState( prevState =>({
      formData : {
        ...prevState.formData,
        changed:true,
        additional_costs : costs,
      }
    }) )
  }
  updateCost = (e,i)=>{
    var costs = [...this.state.formData.additional_costs];
    costs[i][e.target.name]=e.target.value;
    this.setState( prevState =>({
      formData : {
        ...prevState.formData,
        changed:true,
        additional_costs : costs
      }
    }) )
  }
  removeCost = (index)=>{
    var costs = [...this.state.formData.additional_costs];
    costs.splice(index, 1);
    this.setState( prevState =>({
      formData : {
        ...prevState.formData,
        changed:true,
        additional_costs : costs
      }
    }))
  }
  validateEmail(mail){
   if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
    {
      return (true)
    }
      return (false)
  }
  sendThanks = () =>{
    JSAlert.confirm('Send "Thank you for your inquiry" message now ?"',"Send thanks",JSAlert.Icons.Info).then((res)=>{
      if(!res)return;
      fetch(this.url+"api.php?data" , {
        method: 'POST',
        mode: 'cors',
        cache: 'default',
        credentials : 'include',
        body : JSON.stringify({
          action : "sendThanks",
          subject : this.state.formData.client_reference,
          to : this.state.formData.client_email,
          recnum : this.state.formData.recnum
        }),
           })
         .then((response) => response.json())
         .then(
            (res) => {
              this.createNotification(res.status,res.message);
            }).catch((error) => {
              if(error)JSAlert.alert(error,null,JSAlert.Icons.Failed)
            });
        })
  }
  sendQuoteClient = () =>{
    let emails = this.state.formData.client_email.split(",");
    if(this.state.formData.changed===true){
      return this.createNotification("warning","In quote are unsaved changes, save them first.");
    }
    for(let i=0;i<emails.length;i++){
      if(!this.validateEmail(emails[i])){
        JSAlert.alert('"'+emails[i]+ '" is not valid email address. Enter valid addresses separated by commas and repeat action ');
        return;
      }
    }
    JSAlert.confirm("This action will send an email with quote to client. Please confirm","Send quote to client",JSAlert.Icons.Info).then((res)=>{
      if(!res)return;

      fetch(this.url+"api2.php?data" , {
    	  method: 'POST',
        mode: 'cors',
        cache: 'default',
    	  credentials : 'include',
    		body : JSON.stringify({
          action : "send_quote_client",
          quote : this.state.formData
        }),
      		 })
    		 .then((response) => response.json())
    		 .then(
            (res) => {
    				this.createNotification(res.status,res.message);
    				if (res.status==="success") {
              this.setState( prevState =>({
                formData : {
                  ...prevState.formData,
                  status:"Send to Customer"
                }
              }))
    				}
            		if (res.status==="error"){
            			if (res.message==="Please login") {
            				JSAlert.alert("Session closed, please sign in again",null,JSAlert.Icons.Failed).then(()=>
                      this.signout()
    				   	     )
            			}
            			else JSAlert.alert(res.message,null,JSAlert.Icons.Failed);
            			return;
            		}
            }).catch((error) => {
             	if(error)JSAlert.alert(error,null,JSAlert.Icons.Failed)
            });
        })
  }
  findParentCard(el){
    while ((el = el.parentElement) && !el.classList.contains("card-body"));
    return el;
  }
  dragOver = (event)=>{
    this.dragCounter++;
    if (event.target.classList.contains("card-body")) {
      event.target.classList.add("dropzoneActive");
    }else {
      let c = this.findParentCard(event.target);
      c.classList.add("dropzoneActive");
    }
  }
  dragLeave = (event)=>{
    this.dragCounter--;
    if(this.dragCounter===0)document.querySelectorAll(".dropzoneActive").forEach((el)=>{
      el.classList.remove("dropzoneActive");
    })
  }
  downloadFile(num){
  	window.open(this.url+"api.php?downloadRecnum="+num+"&stamp"+new Date().toUTCString() );
  }
  deleteAttachment = (num,waiting,carrierIndex)=>{
    if(waiting===true){
      let carriers = this.state.formData.carriers;
      carriers[carrierIndex].newFiles.splice(num,1);
      console.log(num,waiting,carrierIndex,carriers[carrierIndex].newFiles)
      this.setState( prevState =>({
        formData : {
          ...prevState.formData,
          carriers : carriers
        }
      }))
    }else {
      JSAlert.confirm("Delete file ?",JSAlert.Icons.Info).then((res)=>{
        if(!res)return
      fetch(this.url+"api2.php?data" , {
        method: 'POST',
        mode: 'cors',
        cache: 'default',
        credentials : 'include',
        body : JSON.stringify({
          action : "deleteQuoteFile",
          quote : this.state.formData.recnum,
          file : num
        }),
           })
         .then((response) => response.json())
         .then(
            (res) => {

                if (res.status==="error"){
                  if (res.message==="Please login") {
                    JSAlert.alert("Session closed, please sign in again",null,JSAlert.Icons.Failed).then(()=>
                      this.signout()
                     )
                  }
                  else JSAlert.alert(res.message,null,JSAlert.Icons.Failed);
                  return;
                }
                else {
                  this.createNotification(res.status,res.message);
                  console.log(res.carriers)
                  this.setState( prevState =>({
                    formData : {
                      ...prevState.formData,
                      carriers : res.carriers
                    }
                  }))
                }

            }).catch((error) => {
              if(error)JSAlert.alert(error,null,JSAlert.Icons.Failed)
            });
      })
    }
  }
  copyRecord(){
    var data = {...this.state.formData};
    data.files = [];
    delete data.recnum;
    data.sent_to_client = null;
    data.sales_rate = "";
    data.sales_rate_validity = "";
    data.reference="";
    data.status="waiting";
    data.created = null;
    this.setState({ formData : data,isNew : true, changed:false } )
  }
  render(){
    const costsRows = this.state.formData.additional_costs.map((cost,i)=>{
      return(
        <tr key={i} >
          <td className="align-bottom" width="10%">
            <button className="btn btn-danger" onClick={()=>this.removeCost(i)}>
              <i className="fa fa-minus"/>
            </button>
          </td>
          <td width="60%">
              <input type="text" className="form-control" value={ cost.text } name="text" onChange= { (e)=> this.updateCost(e,i) } ></input>
          </td>
          <td width="30%">
            <div className="input-group">
              <input type="text" className="form-control" value={ cost.val } name="val" onChange= { (e)=> this.updateCost(e,i) } ></input>
              <div className="input-group-append">
                <span className="input-group-text">€</span>
              </div>
            </div>
          </td>
        </tr>
      )
    })
    const carrierRows = this.state.formData.carriers.map((carrier,i)=>{
      if(carrier.validity!=="")carrier.validity=moment(carrier.validity)
      return(
        <div key={i} >
          <div className="card mt-1 mb-2"  >
            <div className={carrier.sent === true ? "card-header bg-success":"card-header" } >
            Carrier {i+1}

            <button className="btn btn-danger btn-sm pull-right" onClick={()=>this.removeCarrier(i)}>
              <i className="fa fa-trash"/>
              <span> Remove from list </span>
            </button>
            {carrier.sent === true && (<span className="small" style={{display:"block"}}>Rate request sent on {carrier.date}</span>)}
            </div>
            <div className="card-body" onDragEnter={(e)=>this.dragOver(e,i)} onDragLeave={(e)=>this.dragLeave(e,i)}  onDrop={(e)=>this.handleFileUpload(e,i)}>
              <div className="row">
                <div className="form-group col-12 col-md-3">
                  <div className="control-label ">Carrier name</div>
                  <div className="">
                    <input type="text" className="form-control" value={ carrier.name } name="name" onChange= { (e)=> this.updateCarrier(e,i) } ></input>
                  </div>
                </div>
                <div className="form-group col-12 col-md-7">
                  <div className="control-label ">Carrier email(s)</div>
                  <div className="">
                    <input type="text" className="form-control" value={ carrier.emails } name="emails" onChange= { (e)=> this.updateCarrier(e,i) } ></input>
                  </div>
                </div>
                <div className="col-12 col-md-2" >
                  <div className="btn-group" style={{marginTop:"24px"}}>
                    <button className="btn btn-primary" type="button" name="client" onClick={ ()=> this.openAddressBook("quote_carrier",i) }><i className="fa fa-address-book" /> <span className="d-none d-sm-inline-block">Open</span></button>
                    <button className="btn btn-success" type="button"  name="client" onClick={ ()=>this.addToAddressBook("carrier",carrier) }><i className="fa fa-plus" /> <span className="d-none d-sm-inline-block">Add</span></button>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="form-group col-12 col-md-3">
                  <div className="control-label">Rate</div>
                  <div className="">
                    <input type="text" className="form-control" placeholder="" name="rate" value={ carrier.rate } onChange = { (e)=> this.updateCarrier(e,i) }/>
                  </div>
                </div>
                <div className="form-group col-12 col-md-3">
                  <div className="control-label">Rate validity</div>
                  <div className="">
                    <DatePicker className="form-control"
    									selected = { carrier.validity }
    								    onChange= { (val) => this.updateCarrier({ target : { name : "validity", value : val } },i) }
    								    dateFormat = "DD.MM.YYYY"
    								    utcOffset={0}
    								    placeholderText = "Click to select date"
    								/>
                  </div>
                </div>
                <div className="form-group col-3">
                  <div className="control-label">Send rate request to this carrier only</div>
                  <button className="btn btn-secondary" type="button" onClick={()=>this.sendQuoteOneCarrier(carrier)}><span className="fa fa-envelope"></span> Send rate request</button>
                </div>
                <div className="form-group col-3">
                  <div className="control-label">Send order and create job</div>
                  <button className="btn btn-primary" type="button" onClick={()=>this.createOrder(carrier)}>Order this carrier</button>
                </div>

                  <div className="col-12">
                  <h6>Attachments
                    <label type="button" className="btn btn-primary btn-sm ml-2">
                      <i className="fa fa-plus"/>
                      <input type="file" className="d-none" onChange={(e)=>this.handleFileUpload(e,i)}/>
                    </label>
                  </h6>
                  {carrier.files!==undefined&&carrier.files!==null&&(
                    <div className="row fileslist col-12">
                    { carrier.files.map((file)=>
                      <div className="list-group-item col-12 col-sm-6 col-md-3">
                      <i className="fa fa-trash" title="Delete attachment" onClick={()=>this.deleteAttachment(file.recnum)} />
                      <button type="button" className="btn btn-secondary" onClick={ ()=> this.downloadFile(file.recnum) }><span className="fa fa-download"></span></button>  { file.name }</div>
                      )
                     }
                    </div>
                  )}
                  </div>

                {carrier.newFiles!==undefined&&carrier.newFiles!==null&&carrier.newFiles.length>0&&(
                  <div className="col-12 mt-2">
                  <h6>Attachments waiting for upload</h6>
                  <div className="row fileslist col-12">
                  { carrier.newFiles.map((file,fi)=>
                    <div className="list-group-item col-12 col-sm-6 col-md-3">
                    <i className="fa fa-trash" title="Delete attachment" onClick={()=>this.deleteAttachment(fi,true,i)} />
                    { file.name }</div>
                    )
                   }
                  </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )
    })
    const dimensionRows = this.state.formData.dimensions.map((item,i)=>
    {return item.packing!=="Container" ? (
    <div className="form-group inline-inputs row" key={i}>
    		<div className="col-1 text-right">
    			<button className="btn btn-danger" type="button" onClick={ ()=> this.delDimensionsRow(item.rowId) }>
    				<span className="fa fa-minus"></span>
    			</button>
    		</div>
    		<div className="col-3 col-md-1">
    			<input className="form-control" name="packing" data-submit="exclude" type="hidden"/>
    			<span className="form-control" name="packing">{item.packing}</span>
    		</div>
    		<div className="col-4 col-md-2">
    			<div className="input-group">
    				<input className="form-control" type="number" min="0" name="length" value={item.length} onChange={ (e)=> this.updateDimensionRow(e,item.rowId) } placeholder='Length'/>
    				<div className="input-group-append">
    					<span className="input-group-text">cm</span>
    				</div>
    			</div>
    		</div>
    		<div className="col-4 col-md-2">
    			<div className="input-group">
    				<input className="form-control" type="number" min="0" name="width" value={item.width} onChange={ (e)=> this.updateDimensionRow(e,item.rowId) } placeholder='Width'/>
    				<div className="input-group-append">
    					<span className="input-group-text">cm</span>
    				</div>
    			</div>
    		</div>
    		<div className="col-4 col-md-2">
    			<div className="input-group">
    				<input className="form-control" type="number" min="0" name="height" value={item.height} onChange={ (e)=> this.updateDimensionRow(e,item.rowId) } placeholder='Height'/>
    				<div className="input-group-append">
    					<span className="input-group-text">cm</span>
    				</div>
    			</div>
    		</div>
    		<div className="col-4 col-md-2">
    			<div className="input-group">
    				<input className="form-control" type="number" min="0" name="pieces" value={item.pieces} onChange={ (e)=> this.updateDimensionRow(e,item.rowId) } placeholder='Number of pieces'/>
    				<div className="input-group-append">
    					<span className="input-group-text">pcs</span>
    				</div>
    			</div>
    		</div>
    		<div className="col-4 col-md-2">
    			<div className="input-group">
    				<input className="form-control" type="text" min="0" name="weight" value={item.weight} onChange={ (e)=> this.updateDimensionRow(e,item.rowId) } placeholder='Weight per piece'/>
    				<div className="input-group-append">
    					<span className="input-group-text">kg</span>
    				</div>
    			</div>
    		</div>

    </div>
    ):(
    	<div className="form-group inline-inputs row dimensionRow" key={i}>
    			<div className="col-1 text-right">
    				<span className="text-muted small"> </span>
    				<button className="btn btn-danger" type="button" onClick={ ()=> this.delDimensionsRow(item.rowId) }>
    					<span className="fa fa-minus"></span>
    				</button>
    			</div>
    			<div className="col-3 col-md-1">
    				<span className="text-muted small"> </span>
    				<input className="form-control" name="packing" data-submit="exclude" type="hidden"/>
    				<span className="form-control" name="packing">{item.packing}</span>
    			</div>
    			<div className="col-4 col-md-1">
    				<span className="text-muted small">Size</span>
    				<div className="input-group">
    				<select className="form-control" value={item.length} name="length" style={{width:"100%"}} onChange={ (e)=> this.updateDimensionRow(e,item.rowId) }>
    					<option>20"</option>
    					<option>30"</option>
    					<option>40"</option>
    					<option>40" HC</option>
    					<option>45" HC</option>
    					<option>45" HW</option>
    					<option>45" PW</option>
    					<option>20" Flatrack</option>
    					<option>40" Flatrack</option>
    					<option>20" Reef</option>
    					<option>40" Reef</option>
    					<option>20" OT</option>
    					<option>40" OT</option>
    					<option>Breakbulk</option>
    					<option>nonstandard</option>
    				</select>
    				</div>
    			</div>
    			<div className="col-4 col-md-1">
    				<span className="text-muted small">Tare weight</span>
    				<div className="input-group">
    					<input className="form-control" type="text" name="tare" value={item.tare} onChange={ (e)=> this.updateDimensionRow(e,item.rowId) } placeholder='Tare weight'/>
    					<div className="input-group-append">
    						<span className="input-group-text">kg</span>
    					</div>
    				</div>
    			</div>
    			<div className="col-4 col-md-2">
    				<span className="text-muted small">Container number</span>
    				<div className="input-group">
    					<input className="form-control" type="text" name="number" value={item.number} onChange={ (e)=> this.updateDimensionRow(e,item.rowId) } placeholder='Container number'/>
    				</div>
    			</div>
    			<div className="col-4 col-md-2">
    				<span className="text-muted small">Seal number</span>
    				<div className="input-group">
    					<input className="form-control" type="text" name="seanumber" value={item.seanumber} onChange={ (e)=> this.updateDimensionRow(e,item.rowId) } placeholder='Seal number of container'/>
    				</div>
    			</div>
    			<div className="col-4 col-md-2">
    				<span className="text-muted small">Equipment</span>
    				<div className="input-group">
    					<input className="form-control" type="text" name="carrier" value={item.carrier} onChange={ (e)=> this.updateDimensionRow(e,item.rowId) } placeholder='Equipment'/>
    				</div>
    			</div>
    			<div className="col-4 col-md-2">
    				<span className="text-muted small">Weight of goods</span>
    				<div className="input-group">
    					<input type="hidden" name="pieces" value="1"/>

    					<input className="form-control" type="text" name="weight" value={item.weight} onChange={ (e)=> this.updateDimensionRow(e,item.rowId) } placeholder='Weight of goods'/>
    					<div className="input-group-append">
    						<span className="input-group-text">kg</span>
    					</div>
    				</div>
    			</div>
    	</div>
    )}
    );
    return(
      <div >
        <div className="btn-group">
          <button type="button" className="btn btn-primary" onClick={ ()=>this.switchView("table") }><i className="fa fa-times"/> Close</button>
          {this.state.isNew===true&&(
              <button type="button" className="btn btn-success" onClick={ ()=>this.saveRecord("saveQuote") }><i className="fa fa-floppy-o"/> Save</button>
          )}
          {this.state.isNew===false&&(
              <button type="button" className="btn btn-success" onClick={ ()=> this.saveRecord("updateQuote") }><i className="fa fa-floppy-o"/> Update</button>
          )}
          {this.state.isNew===false&&(
              <button type="button" className="btn btn-danger" onClick={ ()=>this.deleteRecord() }><i className="fa fa-trash"/> Delete</button>
          )}
          {this.state.isNew===false&&(
            <button className="btn btn-secondary" type="button" onClick={()=>this.copyRecord()}><i className="fa fa-copy"/> Copy record</button>
          )}
        </div>
        <div className="row">
          <div className="form-group col-12 col-md-6 col-lg-3">
            <div className="control-label ">Quotation Number</div>
            <div className="">
              <input type="text" className="form-control" value={ this.state.formData.reference } readOnly placeholder="Will be assigned automatically" ></input>
            </div>
          </div>
          <div className="form-group col-12 col-md-3">
            <div className="control-label" >Kind of transport</div>
              <select className="form-control" name="kind" value={ this.state.formData.kind } onChange = { (e)=> this.quoteHandleChange(e) }>
                <option value="">Select</option>
                <option value="FTL">FTL</option>
                <option value="FCL">FCL</option>
                <option value="LTL">LTL</option>
                <option value="LCL">LCL</option>
                <option value="Rail & Road">Rail & Road</option>
              </select>
          </div>
          {(this.state.formData.kind==="FCL" || this.state.formData.kind==="LCL")&&(
            <div className="form-group col-12 col-md-3">
              <div className="control-label" >Direction</div>
                <select className="form-control" name="direction" value={ this.state.formData.direction } onChange = { (e)=> this.quoteHandleChange(e) }>
                  <option value="">Select</option>
                  <option value="FTL">Export</option>
                  <option value="FCL">Import</option>
                </select>
            </div>
          )}
        </div>
        <div className="row">
          <div className="form-group col-12 col-md-6 col-lg-3">
            <div className="control-label ">Status</div>
            <div className="">
              <select className="form-control" name="status" value={this.state.formData.status} onChange= { (e)=> this.quoteHandleChange(e) }>
                <option value="waiting">Waiting for reply</option>
                <option value="Send to Customer">Send to Customer</option>
                <option value="accepted">Accepted</option>
              </select>
            </div>
          </div>
          {this.state.isNew===false&&(
          <div className="form-group col-12 col-md-6 col-lg-3">
            <div className="control-label ">Created</div>
            <div className="">
              <span className="form-control">{moment(this.state.formData.created).format("DD.MM.YYYY HH:mm")}</span>
            </div>
          </div>
        )}
        </div>
        <h5>Client</h5>
        <hr/>
        <div className="row">
          <div className="form-group col-lg-5 col-12">
            <div className="control-label">Client name</div>
            <div className="">
              <input type="text" className="form-control" value={ this.state.formData.client_name } name="client_name" onChange= { (e)=> this.quoteHandleChange(e) } ></input>
            </div>
          </div>
          <div className="form-group col-lg-5 col-12">
            <div className="control-label">Client email(s)
              <i className="fa fa-question-circle-o text-muted" style={{marginLeft:"10px"}} data-tip="Recipients of auto generated messages. Enter valid email addresses separated by commas"></i><ReactTooltip ></ReactTooltip>
            </div>
            <div className="">
              <input type="text" placeholder="email addresses separated by commas" className="form-control" value={ this.state.formData.client_email } name="client_email" onChange= { (e)=> this.quoteHandleChange(e) }></input>
            </div>
          </div>
          <div className="col-2" >
            <div className="btn-group" style={{marginTop:"24px"}}>
              <button className="btn btn-primary" type="button" name="client" onClick={ ()=> this.openAddressBook("quote_client") }><i className="fa fa-address-book" /> <span className="d-none d-sm-inline-block">Open</span></button>
              <button className="btn btn-success" type="button"  name="client" onClick={ ()=>this.addToAddressBook("client") }><i className="fa fa-plus" /> <span className="d-none d-sm-inline-block">Add</span></button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-lg-10 col-12">
            <div className="control-label">Email subject</div>
            <div className="">
              <input type="text" className="form-control" maxLength="250" value={ this.state.formData.client_reference } name="client_reference" onChange= { (e)=> this.quoteHandleChange(e) } ></input>
            </div>
          </div>
          {this.state.isNew===false&&(
            <div className="form-group col-lg-2 col-12 mt-4">
              <button type="button" className="btn btn-primary" onClick={()=>this.sendThanks()}><i className="fa fa-envelope"/> Send thanks for inquiry</button>
            </div>
          )}
        </div>
        <div className="row">
          <div className="form-group col-12 col-md-3">
            <div className="control-label">Sales rate</div>
            <div className="">
              <div className="input-group">
                <input type="text" className="form-control" value={ this.state.formData.sales_rate } name="sales_rate" onChange= { (e)=> this.quoteHandleChange(e) } ></input>
                <div className="input-group-append">
                  <span className="input-group-text">€</span>
                </div>
              </div>
            </div>
          </div>
          <div className="form-group col-12 col-md-3">
            <div className="control-label">Validity</div>
            <div className="">
              <DatePicker className="form-control"
                selected = { this.state.formData.sales_rate_validity }
                  onChange= { (val) => this.quoteHandleChange({ target : { name : "sales_rate_validity", value : val } }) }
                  dateFormat = "DD.MM.YYYY"
                  placeholderText = "Click to select date"
              />
            </div>
          </div>
        </div>
        <div className="row">
        <div className="form-group col-lg-3 col-12">
           <div className="control-label">Fuel included</div>
           <div className="">
           <div className="toggle-switch toggle-switch-primary" style={{marginTop:"5px"}}>
            <label>
              <input type="checkbox" className="form-check-input" name="fuel_included" checked={this.state.formData.fuel_included} onChange = { (e)=> this.quoteHandleChange(e) } />
              <div className="toggle-switch-inner yesno"></div>
              <div className="toggle-switch-switch"><i className="fa fa-check"></i></div>
            </label>
          </div>
          </div>
        </div>
      </div>
        <div className="form-group">
          <div className="control-label">Additional costs</div>
          <div className="">

            <table style={{width:"100%"}}>
              <thead>
                <tr>
                  <td width="10%">
                    <button type="button" className="btn btn-primary" onClick={ this.addCostsRow }>
                      <span className="fa fa-plus"></span>
                    </button>
                  </td>
                  <td width="60%" className="small text-muted">Description</td>
                  <td width="30%" className="small text-muted">Value</td>
                </tr>
              </thead>
              <tbody>
                {costsRows}
              </tbody>
            </table>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-12">
            <div className="control-label ">Special agreements</div>
            <div className="">
              <textarea type="text" className="form-control" value={ this.state.formData.special_agreements } name="special_agreements" onChange= { (e)=> this.quoteHandleChange(e) } />
            </div>
          </div>
          <div className="form-group col-6">
          {this.state.isNew===false&&(
            <button className="btn btn-primary" type="button" onClick={()=>this.sendQuoteClient()}><i className="fa fa-envelope"/> Send quote to client</button>
            )}
          </div>
          <div className="form-group col-6">
          {this.state.formData.sent_to_client!==null&&(
            <span className="bg-success p-2">Quote to client sent on {moment(this.state.formData.sent_to_client).format("DD.MM.YYYY HH:mm")}</span>
            )}
          </div>
        </div>

        <h5>Cargo</h5>
        <hr/>
        <div className="form-group row">
          <label className="control-label col-3">Packing</label>
          <div className=" col-12 row">
            <div className="col-12 col-md-6 col-lg-3">
              <select className="form-control" name="packing" onChange={ (e)=> this.setState({ selected_packing : e.target.value }) }>
                <option value="Pallet">Pallet</option>
                <option value="Box">Box</option>
                <option value="Container">Container</option>
                <option value="Case">Case</option>
                <option value="Bag">Bag</option>
                <option value="Sack">Sack</option>
                <option value="trunk">Trunk</option>
                <option value="Other">Other</option>
              </select>
            </div>
            <div className="col-3">
              <button type="button" className="btn btn-primary" onClick={ this.addDimensionsRow }>
                <span className="fa fa-plus"></span>
              </button>
            </div>
          </div>
        </div>
        <div className="row">
        {dimensionRows }
        </div>
        <div className="row">
          <div className="form-group col-12 col-lg-3">
            <div className="control-label">DG / Non DG</div>
            <div className="">
              <select className="form-control" name="danger_goods" value={ this.state.formData.danger_goods } onChange = { (e)=> this.quoteHandleChange(e) }>
                <option value="">Please select</option>
                <option value="dg">DG</option>
                <option value="nondg">Non DG</option>
              </select>
            </div>
          </div>
          <div className="form-group col-12">
            <div className="control-label">Customs clearance</div>
            <div className="">
              <div className="toggle-switch toggle-switch-primary" style={{marginTop:"5px"}}>
               <label>
                 <input type="checkbox" className="form-check-input" name="customs_clearance" checked={this.state.formData.customs_clearance} onChange = { (e)=> this.quoteHandleChange(e) } />
                 <div className="toggle-switch-inner yesno"></div>
                 <div className="toggle-switch-switch"><i className="fa fa-check"></i></div>
               </label>
             </div>
            </div>
          </div>
        </div>
        <div className="row">
        <div className="form-group col-lg-3 col-12">
           <div className="control-label">Temperature required</div>
           <div className="">
           <div className="toggle-switch toggle-switch-primary" style={{marginTop:"5px"}}>
            <label>
              <input type="checkbox" className="form-check-input" name="temperature_required" checked={this.state.formData.temperature_required} onChange = { (e)=> this.quoteHandleChange(e) } />
              <div className="toggle-switch-inner yesno"></div>
              <div className="toggle-switch-switch"><i className="fa fa-check"></i></div>
            </label>
          </div>
          </div>
        </div>
        {this.state.formData.temperature_required===true && (
          <div className="form-group col-3">
            <div className="control-label ">Temperature </div>
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">&#8451;</span>
              </div>
              <input type="text" className="form-control" maxLength="10" name="temperature" value={ this.state.formData.temperature } onChange = { (e)=> this.quoteHandleChange(e) }></input>
            </div>
          </div>
        )}
      </div>
      <div className="row">
        <div className="form-group col-12">
          <div className="control-label ">Special instructions</div>
          <div className="">
            <textarea className="form-control" name="special_instructions" value={ this.state.formData.special_instructions } onChange = { (e)=> this.quoteHandleChange(e) }/>
          </div>
        </div>
      </div>
        <h5>Addresses</h5>
        <hr/>
        <div className="row">
        <div className="col-sm-12 col-md-6 mt-1">
          <div className="card">
            <h5 className="card-header">
            (1) Pickup
            </h5>
            <div className="card-body">
              <div className="form-group">
                <div className="control-label col-sm-12 col-md-4">Address</div>
                <div className="col-sm-12 col-md-9">
                  <input type="text" className="form-control" placeholder="Street" name="pickup_street" value={ this.state.formData.pickup_street } onChange = { (e)=> this.quoteHandleChange(e) }/>
                  <input type="text" className="form-control" placeholder="City" name="pickup_city" value={ this.state.formData.pickup_city } onChange = { (e)=> this.quoteHandleChange(e) }/>
                  <input type="text" className="form-control" placeholder="Country" maxLength="100" name="pickup_country" value={ this.state.formData.pickup_country } onChange = { (e)=> this.quoteHandleChange(e) }/>
                  <input type="text" className="form-control" placeholder="Post code" maxLength="15" name="pickup_zip" value={ this.state.formData.pickup_zip } onChange = { (e)=> this.quoteHandleChange(e) }/>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-sm-12 col-md-6 mt-1 mb-1">
          <div className="card">
            <h5 className="card-header">
            (2) Posit
            </h5>
            <div className="card-body">
              <div className="form-group">
                <div className="control-label col-sm-12 col-md-4">Address</div>
                <div className="col-sm-12 col-md-9">
                  <input type="text" className="form-control" placeholder="Street" name="delivery_street" value={ this.state.formData.delivery_street } onChange = { (e)=> this.quoteHandleChange(e) }/>
                  <input type="text" className="form-control" placeholder="City" name="delivery_city" value={ this.state.formData.delivery_city } onChange = { (e)=> this.quoteHandleChange(e) }/>
                  <input type="text" className="form-control" placeholder="Country" maxLength="100" name="delivery_country" value={ this.state.formData.delivery_country } onChange = { (e)=> this.quoteHandleChange(e) }/>
                  <input type="text" className="form-control" placeholder="Post code" maxLength="15" name="delivery_zip" value={ this.state.formData.delivery_zip } onChange = { (e)=> this.quoteHandleChange(e) }/>
                </div>
              </div>
            </div>
          </div>
        </div>
        {(this.state.formData.kind!=="FTL"&&this.state.formData.kind!=="LTL") && (
        <div className="col-sm-12 col-md-6 mt-1 mb-1">
          <div className="card">
            <h5 className="card-header">
            (3) Dropoff
            </h5>
            <div className="card-body">
              <div className="form-group">
                <div className="control-label col-sm-12 col-md-4">Address</div>
                <div className="col-sm-12 col-md-9">
                  <input type="text" className="form-control" placeholder="Street" name="dropoff_street" value={ this.state.formData.dropoff_street } onChange = { (e)=> this.quoteHandleChange(e) }/>
                  <input type="text" className="form-control" placeholder="City" name="dropoff_city" value={ this.state.formData.dropoff_city } onChange = { (e)=> this.quoteHandleChange(e) }/>
                  <input type="text" className="form-control" placeholder="Country" maxLength="100" name="dropoff_country" value={ this.state.formData.dropoff_country } onChange = { (e)=> this.quoteHandleChange(e) }/>
                  <input type="text" className="form-control" placeholder="Post code" maxLength="15" name="dropoff_zip" value={ this.state.formData.dropoff_zip } onChange = { (e)=> this.quoteHandleChange(e) }/>
                </div>
              </div>
            </div>
          </div>
        </div>
        )}
        {(this.state.formData.customs_clearance===true) && (
        <div className="col-sm-12 col-md-6 mt-1 mb-1">
         <div className="card">
           <h5 className="card-header">
           (4) Customs stop
           </h5>
           <div className="card-body">
             <div className="form-group">
               <div className="control-label col-sm-12 col-md-4">Address</div>
               <div className="col-sm-12 col-md-9">
                 <input type="text" className="form-control" placeholder="Street" name="customs_street" value={ this.state.formData.customs_street } onChange = { (e)=> this.quoteHandleChange(e) }/>
                 <input type="text" className="form-control" placeholder="City" name="customs_city" value={ this.state.formData.customs_city } onChange = { (e)=> this.quoteHandleChange(e) }/>
                 <input type="text" className="form-control" placeholder="Country" maxLength="100" name="customs_country" value={ this.state.formData.customs_country } onChange = { (e)=> this.quoteHandleChange(e) }/>
                 <input type="text" className="form-control" placeholder="Post code" maxLength="15" name="customs_zip" value={ this.state.formData.customs_zip } onChange = { (e)=> this.quoteHandleChange(e) }/>
               </div>
             </div>
           </div>
           </div>

        </div>
        )}
        </div>
        <h5>Carriers</h5>
        <hr/>
        <div className="row">
          <div className="btn-group mb-1 col-12">
            <button type="button" className="btn btn-primary" onClick={ this.addCarrier }>
              <span className="fa fa-plus"></span> Add carrier
            </button>
            {this.state.isNew===false && (
            <button type="button" className="btn btn-secondary" onClick={ this.sendQuoteCarriers }>
              <span className="fa fa-envelope"></span> Send rate request to all carriers
            </button>
            )}
          </div>
        </div>
        <div  >
        {carrierRows}
        </div>
        <div className="btn-group mb-2">
          <button type="button" className="btn btn-primary" onClick={ ()=>this.switchView("table") }><i className="fa fa-times"/> Close</button>
          {this.state.isNew===true&&(
              <button type="button" className="btn btn-success" onClick={ ()=>this.saveRecord("saveQuote") }><i className="fa fa-floppy-o"/> Save</button>
          )}
          {this.state.isNew===false&&(
              <button type="button" className="btn btn-success" onClick={ ()=> this.saveRecord("updateQuote") }><i className="fa fa-floppy-o"/> Update</button>
          )}
          {this.state.isNew===false&&(
              <button type="button" className="btn btn-danger" onClick={ ()=>this.deleteRecord() }><i className="fa fa-trash"/> Delete</button>
          )}
        </div>
      </div>
    )
  }
}
