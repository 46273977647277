import React from 'react';
import { PDFDownloadLink, Document, Page,View,StyleSheet,Text } from '@react-pdf/renderer';
import moment from 'moment';
export function NotesDownload(props) {
  const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      backgroundColor: '#E4E4E4'
    },
    section: { margin: 30 },
    row : {fontSize:10}
  });

  // Create Document Component
  const MyDoc = () => (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text style={{marginBottom:5}}>Internal notes/comments for job : {props.job}</Text>
          {props.rows.map((row)=>{
            row = JSON.parse(row)
            return <Text style={styles.row}>On {moment(row.date).format("DD.MM.YYYY HH:mm:ss")} : {row.text}</Text>
          })}
        </View>
      </Page>
    </Document>
  );
return(
  <div>
    <PDFDownloadLink document={<MyDoc />} fileName={props.job + " notes_comments"}>
      {({ blob, url, loading, error }) =>
        <button type="button" className="btn btn-secondary btn-sm"><i className="fa fa-download"/> Download notes</button>
      }
    </PDFDownloadLink>
  </div>
);
}
