import React, { Component } from 'react';
import ReactTable from "react-table";
import moment from 'moment';
import JSAlert from 'js-alert';
import {QuoteStats} from '../modals';

const defaultSearch = {
  reference : "",
  currentMonth : moment().month()+1,
  currentYear : moment().year()
}
export default class QuotesOverview extends Component {
  constructor(props) {
    super(props);
    this.url = props.url;
    this.switchView=props.switchView;
    this.signout = props.signout;
    this.state={
      tableData : [],
      visibleTableData : [],
      searchForm : defaultSearch,
      displayStatsModal : false,
      tableFilterInputs : {
        posit : "",
        pickup : "",
        client_name : "",
        reference:""
      }
    }
  }
  componentDidMount(){
    this.fetchTableData()
  }
  fetchTableData(){
    fetch(this.url+"api2.php?data", {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify({action:"searchQuotes",...this.state.searchForm})
       })
     .then((response) => response.json())
     .then(
        (res) => {
            if (res.status==="error"){
              if (res.message==="Please login") {
                JSAlert.alert("Session closed, please sign in again",null,JSAlert.Icons.Failed).then(()=>
                 this.signout()
            )
              }
              else JSAlert.alert(res.message,null,JSAlert.Icons.Failed);
              return;
            }
            if(typeof res !=="object"){
              JSAlert.alert("Received invalid response ("+(typeof res)+"), refresh results table manually",null,JSAlert.Icons.Failed);
              return;
            }
            res = res.map((r)=>{
              let pickup = [];
              if(r.pickup_street!=="")pickup.push(r.pickup_street);
              if(r.pickup_city!=="")pickup.push(r.pickup_city);
              if(r.pickup_zip!=="")pickup.push(r.pickup_zip);
              if(r.pickup_country!=="")pickup.push(r.pickup_country);
              pickup = pickup.join(",");
              let delivery = [];
              if(r.delivery_street!=="")delivery.push(r.delivery_street);
              if(r.delivery_city!=="")delivery.push(r.delivery_city);
              if(r.delivery_zip!=="")delivery.push(r.delivery_zip);
              if(r.delivery_country!=="")delivery.push(r.delivery_country);
              delivery = delivery.join(",");
              return {
                ...r,pickup:pickup,delivery:delivery
              }
            });

            this.setState({ tableData : res },()=>{
              this.applyFilters();
            } )
        }).catch((error) => {
          if(error)JSAlert.alert(error,null,JSAlert.Icons.Failed)
        });
  }
  searchFormHandleChange = (e)=>{
    let name = e.target.name;
    let val = e.target.value;
    this.setState( prevState =>({
      searchForm : {
        ...prevState.searchForm,
        [name]:val
      }
    }),()=>{
      this.fetchTableData()
    })
  }
  tableFilter(e){
    let inputs = {...this.state.tableFilterInputs};
    inputs[e.target.name]=e.target.value;

    this.setState({tableFilterInputs : inputs },()=>{
      this.applyFilters();
    });
  }
  applyFilters(){
    let data = [...this.state.tableData];
    let inputs = {...this.state.tableFilterInputs};
    Object.keys(inputs).forEach((key, i) => {
      if(inputs[key].length===0)return
      data = data.filter((r)=>{
        let val = r[key];
        val=val.toLowerCase();

        return val.indexOf(inputs[key].toLowerCase())!==-1
      })
    });
    this.setState({visibleTableData : data });
    if(data.length===1000){
      this.createNotification("warning","Limit 1000 rows reached !!")
    }
  }
  toggleStatsModal = ()=>{
    this.setState( prevState =>({ displayStatsModal : !prevState.displayStatsModal}));
  }
  render(){
    const styles ={
      headinput : {
        width : "100%"
      }
    }
    const columns = [
  		{
        Cell : props => <button className="btn btn-primary btn-sm" onClick={ () => this.switchView("detail",props.value) } id={props.value}>Open</button>,
  	    accessor: 'recnum',
  			sortable : false,
  	    width: 70,
  			},
      {
        Header: <div>Quotation number<br/><input style={styles.headinput} placeholder = "Filter" name="reference" value = { this.state.tableFilterInputs.reference } onChange={(e)=>this.tableFilter(e)} onClick={ (e)=>e.stopPropagation() } /></div>,
        accessor: 'reference',
        width: 120,
        },
      {
        Header: <div>Client<br/><input style={styles.headinput} placeholder = "Filter" name="client_name" value = { this.state.tableFilterInputs.client_name } onChange={(e)=>this.tableFilter(e)} onClick={ (e)=>e.stopPropagation() } /></div>,
        accessor: 'client_name',
        },
      {
        Header: <div>Pickup<br/><input style={styles.headinput} placeholder = "Filter" name="pickup" value = { this.state.tableFilterInputs.pickup } onChange={(e)=>this.tableFilter(e)} onClick={ (e)=>e.stopPropagation() } /></div>,
        accessor: 'pickup',
        },
      {
        Header: <div>Posit<br/><input style={styles.headinput} placeholder = "Filter" name="delivery" value = { this.state.tableFilterInputs.delivery } onChange={(e)=>this.tableFilter(e)} onClick={ (e)=>e.stopPropagation() } /></div>,
        accessor: 'delivery',
        }
      ];
    return(
      <div>
        <div className="mb-2">

          <div className="row">
            <div className="col-12 col-md-6">
              <button className="btn btn-primary" onClick={()=>this.switchView("detail","new")}>New quote</button>
            </div>
            <div className="form-group col-12 col-md-6">
              <div className="control-label">Month & year</div>
              <div className="row col-12">
                <select className="form-control col-6" name="currentMonth" value={ this.state.searchForm.currentMonth } onChange = { (e)=> this.searchFormHandleChange(e) }>
                  <option value="0">All</option>
                  <option value="1">January</option>
                  <option value="2">February</option>
                  <option value="3">March</option>
                  <option value="4">April</option>
                  <option value="5">May</option>
                  <option value="6">June</option>
                  <option value="7">July</option>
                  <option value="8">August</option>
                  <option value="9">September</option>
                  <option value="10">October</option>
                  <option value="11">November</option>
                  <option value="12">December</option>
                </select>
                <select className="form-control col-5" name="currentYear" value={ this.state.searchForm.currentYear } onChange = { (e)=> this.searchFormHandleChange(e) }>
                  <option value="0">All</option>
                  {(()=>{
                    let a=[];
                    for(let i = 2022; i<moment().year()+2;i++){
                      a.push(i)
                    }
                    return a.map((b,i)=>{ return <option key={i} value={b}>{b}</option>  })
                  })()
                  }
                </select>
                <button className="btn btn-secondary" type="button" onClick={this.toggleStatsModal}>
                  <i class="fa fa-area-chart" aria-hidden="true"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
          <ReactTable
            className="-highlight maintable row"
            data={this.state.visibleTableData}
            pageSize = {this.state.visibleTableData.length}
            columns={columns}
            showPagination={false}
            getTrProps={(state, rowInfo, column) => {
                if (rowInfo === undefined) {
                    return {}
                }
                let color;
                switch(rowInfo.original.status){
                  case "accepted":
                    color="#a9d18e";
                  break;
                  case "Send to Customer":
                    color="#b4c7e7";
                  break;
                  default:
                  break
                }
                return {
                    style:{"backgroundColor":color}
                    //'data-visible': (rowInfo.original.special_instructions!==null && rowInfo.original.special_instructions!=='') ? true : false
                }
            }}
          />
          {this.state.displayStatsModal===true &&(
          <QuoteStats
            show={this.state.displayStatsModal}
            hideModal={this.toggleStatsModal}
            url={this.url}
            searchForm={this.state.searchForm}
            />
          )}
      </div>
    )
  }
}
