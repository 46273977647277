import React,{Component } from 'react';
import mwlogo from './f4w-logo.png';
import zlogo from './z-technics-logo.png';
import YoutubeBackground from 'react-youtube-background';
class Login extends Component {

	 constructor(props) {
		 setTimeout(()=>{
			 let el = document.getElementById("loginForm");
			 if(el!==null&&el!==undefined)el.classList.add("show");
		 },1000)
    super(props);
		this.url = props.url;
    this.loginHandle = this.loginHandle.bind(this);
		this.value = props.value;
    this.state = {
		loginError : ''
    }

 }
 componentDidMount(){
	 //check if logedIn
	 	if (!this.value) {
	 	fetch(this.url+"api.php?action=checkIfLoged" , {
	 		method: 'GET',
	 		credentials: 'include'
	   		 })
	 		 .then((response) => response.json())
	 		 .then(
	         (res) => {
	         	if (res.status==="error") {
	 				this.setState({ loginError : res.message	})
	 			}
	 			if (res.status==="success") {
	 				this.props.onLoginSuccess(res);

	 			}
	 		}).catch(
	          (error) => {
	          	alert(error)
	           this.setState({
	             isLoaded: true,
	             error
	           });
	         })

	  	}
 }
 loginHandle(e){
 	e.preventDefault();
	fetch(this.url+"api.php?action=login" , {
		method: 'POST',
		credentials: 'include',
		body : JSON.stringify({"username":encodeURI(e.target.username.value),"password": encodeURI(e.target.password.value)})
  		 })
		 .then((response) => response.json())
		 .then(
        (res) => {
        	if (res.status==="error") {
				this.setState({ loginError : res.message	})
			}
			if (res.status==="success") {
				this.props.onLoginSuccess(res);
				this.setState({ userLoged:true });
			}
		}).catch(
         (error) => {
         	alert(error)
          this.setState({
            isLoaded: true,
            error
          });
        })
 }
	render(){
    return (
<YoutubeBackground style={{"height":"100%"}}
  videoId='WGpNskzMoDs'
  className ="container-fluid"
>
	<div className="row">
		<div className="col-md-4 offset-md-4 mt-4" id="loginForm">
        <form className="form"  onSubmit={ this.loginHandle }>
            <div className="row mt-2">
                <div className="col-md-12 text-center">
                <a href="https://www.mauriceward.com"><img src={mwlogo} className="mwlogo"/></a>
                    <p>Please Login</p>
                    <hr/>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="form-group has-danger">
                        <label className="sr-only" htmlFor="email">Username</label>
                        <div className="input-group mb-2 mr-sm-2 mb-sm-0">
                            <div className="input-group-addon" style={{width: 2.6+"rem"}}><i className="fa fa-user"></i></div>
                            <input type="text" name="username" className="form-control" id="email"
                                   placeholder="Username" required autoFocus autoComplete="off" />
                        </div>
                    </div>
                </div>

            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="form-group">
                        <label className="sr-only" htmlFor="password">Password</label>
                        <div className="input-group mb-2 mr-sm-2 mb-sm-0">
                            <div className="input-group-addon" style={{width: 2.6+"rem"}}><i className="fa fa-key"></i></div>
                            <input type="password" name="password" className="form-control" id="password"
                                   placeholder="Password" required/>
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="form-control-feedback">
                        <span className="text-danger align-middle">

                        </span>
                    </div>
                </div>
                <div className="col-md-12 text-center">
                    <div className="form-control-feedback">
                        <span className="text-danger align-middle">
                           { this.state.loginError }
                        </span>
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-center mb-4" style={{paddingTop: 1+"rem"}}>
                    <button type="submit" className="btn btn-success" ><i className="fa fa-sign-in"></i> Login</button>
            </div>
        </form>
		</div>
	</div>
	<div id="ztech">
		<p className="text-muted">Powered by</p><a href="https://www.z-technics.com/en"><img src={zlogo}/></a>
	</div>
</YoutubeBackground>
    );
 }
};

export default Login;
