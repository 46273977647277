import React from 'react';
export default function ListEl(props){
  let value = props.value===undefined ? [] :props.value.split(" ");
  if(value.length===1&&value[0]==="")value=[];
  let input = React.createRef();
  let name=props.name;
  let onChange= (e)=>{
  //  setInput(e.target.value);
  }
  let onKeyPress = (e)=>{
    if(e.key!=="Enter")return;
    if(e.target.value==="")return;
    value.push(e.target.value);
    props.onChange({target : { name: name, value : value.join(" ") }})
    e.target.value="";
  }
  let onBlur = (e)=>{
    if(e.target.value==="")return;
    value.push(e.target.value);
    props.onChange({target : { name: name, value : value.join(" ") }})
    e.target.value="";
  }
  let del = (i)=>{
    delete value[i];
    props.onChange({target : { name: name, value : value.join(" ") }})
  }
  let add = ()=>{
    if(input.current.value==="")return
    value.push(input.current.value)
    props.onChange({target : { name: name, value : value.join(" ") }})
    input.current.value="";
  }
return(
  <div>
    <div className="input-group">
    <input type="text"
        className="form-control"
        name = {name}
        ref={input}
        maxLength = {props.maxLength}
        onChange = {(e)=>onChange(e)}
        onKeyPress = {(e)=>onKeyPress(e)}
        onBlur = {(e)=>onBlur(e)}
    />
    <div className="input-group-append">
      <button className="btn btn-primary" type="button" onClick={()=>add()}><i className="fa fa-plus" ></i></button>
    </div>
  </div>
  <div className="list-group mt-1">
    {value.map((v,i)=>{
      if(v==="")return
        return(
          <div className="list-group-item pt-2 pb-2">
            <span>{v}</span>

            <button type="button" className="btn btn-danger btn-sm float-right"><i className="fa fa-minus" onClick={()=>del(i)}></i></button>
          </div>
        )
    })}

  </div>
  </div>
  )
}
