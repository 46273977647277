import React from 'react';
import JSAlert from 'js-alert';

export function ClientModal(props) {
  const show = props.show;
  const [modalFileSelection,setModalFileSelection] = React.useState([]);
  const files=props.files;
  const displayBackdrop = props.show;
  const hideClientModal = props.hideClientModal;
  const shipment = props.shipment;
  const url=props.url;
  const createNotification = props.createNotification;
  const selectModalFile = (isOn,file)=>{
		let files = [...modalFileSelection];
		if(isOn===true){
			files.push(file[0]);
		}else {
			files=files.filter((f)=>{ return f!==file[0]})
		}
		setModalFileSelection(files)
	}
  const sendClientConfirmation=()=>{

    var o = {
      action:'send_client_booking',
      recnum : shipment.recnum,
      mail : shipment.notice_emails,
      files : modalFileSelection,
      quotation_number : shipment.quotation_number,
      job : shipment.nl_ref,
      client_ref : shipment.client_reference,
      sales_rate : shipment.sales_rate
    }
  fetch(url+"api.php?data", {
    method: 'POST',
    mode: 'cors',
    cache: 'default',
    credentials : 'include',
    body : JSON.stringify(o),
     })
   .then((response) => response.json())
   .then(
      (res) => {
        createNotification(res.status,res.message);
        hideClientModal(res.new_comment);
      }).catch((error) => {
        if(error)JSAlert.alert(error,null,JSAlert.Icons.Failed)
      });

  }
  React.useEffect(() => { setModalFileSelection([]); console.log("uklizim") },[]);
  return (
    <div>
    <div className={"modal modal-open fade show"} tabindex="-1" role="dialog" id="client_mail_dialog" style={{display: show===true ? "block":"none"}}>
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Client confirmation</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={()=>{hideClientModal()}}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>System is going to send Booking confirmation to {shipment.notice_emails}. <br/> Do you want to attach some file to the email ?</p>
            <div>
              <h5>List of files</h5>
              { files.map((file)=>
                <div className="list-group-item">
                  <div className="checkbox">
                  <label>
                    <input
                      type="checkbox"
                      checked={modalFileSelection.indexOf(file[0])!==-1 ? true:false}
                      onChange={(e)=>selectModalFile(e.target.checked,file)}
                    />
                    <i className="fa fa-square-o" />
                    <span>{ file[1] }</span>
                  </label></div>
                </div>
                )
               }
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" onClick={()=>sendClientConfirmation()}>Send</button>
            <button type="button" class="btn btn-secondary" data-dismiss="modal" onClick={()=>{ hideClientModal() }}>Close</button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop fade show" id="backdrop" style={{display: displayBackdrop===true ? "block":"none"}}></div>
    </div>
  );
}
export function CarrierModal(props) {
  const show = props.show;
  const [modalFileSelection,setModalFileSelection] = React.useState([]);
  const files=props.files;
  const displayBackdrop = props.show;
  const hideCarrierModal = props.hideCarrierModal;
  const shipment = props.shipment;
  const url=props.url;
  const createNotification = props.createNotification;
  const selectModalFile = (isOn,file)=>{
		let files = [...modalFileSelection];
		if(isOn===true){
			files.push(file[0]);
		}else {
			files=files.filter((f)=>{ return f!==file[0]})
		}
		setModalFileSelection(files)
	}
  const sendCarrierConfirmation=()=>{

    var o = {
      action:'send_carrier_booking',
      recnum : shipment.recnum,
      mail : shipment.carrier_emails,
      files : modalFileSelection,
      quotation_number : shipment.quotation_number,
      job : shipment.nl_ref,
    }
  fetch(url+"api.php?data", {
    method: 'POST',
    mode: 'cors',
    cache: 'default',
    credentials : 'include',
    body : JSON.stringify(o),
     })
   .then((response) => response.json())
   .then(
      (res) => {
        createNotification(res.status,res.message);
        hideCarrierModal(res.new_comment);
      }).catch((error) => {
        if(error)JSAlert.alert(error,null,JSAlert.Icons.Failed)
      });

  }
  React.useEffect(() => { setModalFileSelection([]); console.log("uklizim") },[]);
  return (
    <div>
    <div className={"modal modal-open fade show"} tabindex="-1" role="dialog" id="client_mail_dialog" style={{display: show===true ? "block":"none"}}>
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Carrier Booking confirmation</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={()=>{hideCarrierModal()}}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>System is going to send Booking confirmation to {shipment.carrier_emails}. <br/> Do you want to attach some file to the email ?</p>
            <div>
              <h5>List of files</h5>
              { files.map((file)=>
                <div className="list-group-item">
                  <div className="checkbox">
                  <label>
                    <input
                      type="checkbox"
                      checked={modalFileSelection.indexOf(file[0])!==-1 ? true:false}
                      onChange={(e)=>selectModalFile(e.target.checked,file)}
                    />
                    <i className="fa fa-square-o" />
                    <span>{ file[1] }</span>
                  </label></div>
                </div>
                )
               }
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" onClick={()=>sendCarrierConfirmation()}>Send</button>
            <button type="button" class="btn btn-secondary" data-dismiss="modal" onClick={()=>{ hideCarrierModal() }}>Close</button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop fade show" id="backdrop" style={{display: displayBackdrop===true ? "block":"none"}}></div>
    </div>
  );
}
export function NotifyModal(props) {
  const show = props.show;
  const [notify_client,setNotify_client] = React.useState(props.shipment.notify_client);
  const [notify_carrier,setNotify_carrier] = React.useState(props.shipment.notify_carrier);
  const shipmentHandleChange = props.shipmentHandleChange;
  const displayBackdrop = props.show;
  const hideModal = props.hideModal;
  const shipment = props.shipment;

  const update = ()=>{
    props.updateRecord(true,{
      notify_carrier : notify_carrier,
      notify_client : notify_client
    });
    hideModal();
  }
  const change = (name,value)=>{
    if(name==="notify_client")setNotify_client(value);
    if(name==="notify_carrier")setNotify_carrier(value);
  }
  return (
    <div>
    <div className={"modal modal-open fade show"} tabindex="-1" role="dialog" id="client_mail_dialog" style={{display: show===true ? "block":"none"}}>
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Send notifications ? </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={()=>{hideModal()}}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>Some of the changed fields should be notified to others. <br/>What notifications do you want to send ?</p>
            <div>
              {shipment.notify_client===true && (
                <div>
                  <div className="checkbox">
                  <label>
                    <input
                      checked={notify_client}
                      type="checkbox"
                      onChange={(e)=>change("notify_client",e.target.checked)}
                    />
                    <i className="fa fa-square-o" />
                    <span>Notify client</span>
                  </label></div>
                </div>
              )}
              {shipment.notify_carrier===true && (
                <div>
                  <div className="checkbox">
                  <label>
                    <input
                      checked={notify_carrier}
                      type="checkbox"
                      onChange={(e)=>change("notify_carrier",e.target.checked)}
                    />
                    <i className="fa fa-square-o" />
                    <span>Notify carrier</span>
                  </label></div>
                </div>
              )}
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" onClick={()=>update()}>Update</button>
            <button type="button" class="btn btn-secondary" data-dismiss="modal" onClick={()=>{ hideModal() }}>Cancel update</button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop fade show" id="backdrop" style={{display: displayBackdrop===true ? "block":"none"}}></div>
    </div>
  );
}
export function EmailModal(props) {
  const show = props.show;
  const [modalFileSelection,setModalFileSelection] = React.useState([]);
  const files=props.shipment.filesnames;
  const displayBackdrop = props.show;
  const hideModal = props.hideEmailModal;
  const createNotification = props.createNotification;
  const url=props.url;
  const shipment = props.shipment;
  var emails = ""; let text = "Good Day,\nplease find attached documents for the job: "+props.shipment.nl_ref;
  let subject = "";

  if(props.to === "carrier"){
    emails = props.shipment.carrier_emails;
    subject = "JOB:"+shipment.nl_ref;
  }
  if(props.to === "client"){
    emails = props.shipment.notice_emails;
    text+="\nClient ref: "+shipment.client_reference;
    subject = "REF:"+shipment.client_reference+" JOB:"+shipment.nl_ref;
  }
  text+="\n\nBest Regards\nFreight4ward";
  const to = props.to;
  const [emailText,setEmailText] = React.useState(text)
  const selectModalFile = (isOn,file)=>{
		let files = [...modalFileSelection];
		if(isOn===true){
			files.push(file[0]);
		}else {
			files=files.filter((f)=>{ return f!==file[0]})
		}
		setModalFileSelection(files)
	}
  const send = () =>{
    let o = {
      action : "sendMailTo",
      mail : emails,
      text :emailText,
      files : modalFileSelection,
      subject: subject
    }
    fetch(url+"api2.php?data", {
      method: 'POST',
      mode: 'cors',
      cache: 'default',
      credentials : 'include',
      body : JSON.stringify(o),
       })
     .then((response) => response.json())
     .then(
        (res) => {
          createNotification(res.status,res.message);
          hideModal();
        }).catch((error) => {
          if(error)JSAlert.alert(error,null,JSAlert.Icons.Failed)
      });
  }
  const changeText = (e)=>{
    setEmailText(e.targer.value);
  }
  const changeDraft = (d)=>{
    let text;
    let subject = "REF:"+shipment.client_reference+" JOB:"+shipment.nl_ref;
    if(d==="docs"){
      text = "Good Day,\nplease find attached documents for the job: "+props.shipment.nl_ref;
      text+="\nClient ref: "+shipment.client_reference;
    }
    if(d==="thanks"){
      text = "Dear Customer,\nthank you for your request,\nWe will send you an offer as soon as possible";
    }
    text+="\n\nBest Regards\nFreight4ward";
    setEmailText(text);
  }
  return (
    <div>
    <div className={"modal modal-open fade show"} tabindex="-1" role="dialog" id="client_mail_dialog" style={{display: show===true ? "block":"none"}}>
      <div class="modal-dialog modal-lg" role="document" style={{minWidth:"80%"}}>
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Send email to {to} </h5>
            {to==="client" && (
              <div className="float-right-text">
              <div>Pre-defined text</div>
              <div className="btn-group">
                <button type="button" className="btn btn-secondary" onClick={()=>changeDraft("docs")}>Docs</button>
                <button type="button" className="btn btn-secondary" onClick={()=>changeDraft("thanks")}>Thanks</button>
              </div>
              </div>
            )}

            <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={()=>{hideModal()}}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <div>To : {emails}</div>
            <div className="form-group">
              <div className="control-label">Text</div>
              <div className="">
                <textarea className="form-control" value={emailText} onChange={changeText} rows="8"></textarea>
              </div>
            </div>
            <div className="form-group">
              <div className="control-label">Select files to attach</div>
              { files.map((file)=>
                <div className="list-group-item">
                  <div className="checkbox">
                  <label>
                    <input
                      type="checkbox"
                      checked={modalFileSelection.indexOf(file[0])!==-1 ? true:false}
                      onChange={(e)=>selectModalFile(e.target.checked,file)}
                    />
                    <i className="fa fa-square-o" />
                    <span>{ file[1] }</span>
                  </label></div>
                </div>
                )
               }
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" onClick={()=>send()}><i className="fa fa-send"/> Send</button>
            <button type="button" class="btn btn-secondary" data-dismiss="modal" onClick={()=>{ hideModal() }}>Cancel</button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop fade show" id="backdrop" style={{display: displayBackdrop===true ? "block":"none"}}></div>
    </div>
  );
}
export function QuoteStats(props) {
  const show = props.show;
  const [stats,setStats] = React.useState([]);
  const [total,setTotal] = React.useState(0);
  const [average,setAverage] = React.useState({});
  const displayBackdrop = props.show;
  const hideModal = props.hideModal;
  const url = props.url;
  const searchForm = props.searchForm;
  React.useEffect(()=>{
    let o ={
      action : "quotes_stats",
      month:searchForm.currentMonth,
      year:searchForm.currentYear
    }
    fetch(url+"api2.php?data", {
        method: 'POST',
        mode: 'cors',
        cache: 'default',
        credentials : 'include',
        body : JSON.stringify(o),
       })
     .then((response) => response.json())
     .then(
        (res) => {
          if(res.status==="success"){
            let total = 0;
            res.data.forEach((row)=>{
              total+=Number(row.count);
            })
            let data = res.data.map((row)=>{
              return {...row,percent : ((Number(row.count)/total)*100).toFixed(2)}
            })
            setStats(data);
            setTotal(total)
            setAverage(res.time)
          }
        }).catch((error) => {
          if(error)JSAlert.alert(error,null,JSAlert.Icons.Failed)
      });
  },[])
  return (
    <div>
    <div className={"modal modal-open fade show"} tabindex="-1" role="dialog" id="client_mail_dialog" style={{display: show===true ? "block":"none"}}>
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Quotes Stats </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={()=>{hideModal()}}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
          <h5>Status overview {searchForm.currentMonth}/{searchForm.currentYear}</h5>
            <table className="table">
            <tr><th>Status</th><th>Count ({total})</th><th>Percents</th></tr>
            {stats.map((row)=>{
              return(
                <tr><td>{row.status.toUpperCase()}</td><td>{row.count}</td><td>{row.percent}</td></tr>
              )
            })}
            </table>
          <h5>Average time between create and send to client</h5>
          <b>{average.average}</b><br/><small>Counted from {average.count} quotes with sent time recorded</small>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop fade show" id="backdrop" style={{display: displayBackdrop===true ? "block":"none"}}></div>
    </div>
  );
}
