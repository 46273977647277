import React, { Component } from 'react';
import QuotesOverview from "./Quotes/overview";
import QuoteDetail from "./Quotes/detail";


export default class Quotes extends Component {
  constructor(props) {
    super(props);
    this.url = props.url;
    this.signout = props.signout;
    this.createNotification=props.createNotification;
    this.openAddressBook = props.openAddressBook;
    this.addToAddressBook = props.addToAddressBook;
    this.quoteFormRef = props.quoteFormRef;
    this.createShipment = props.createShipment;
    this.quoteTableRef = React.createRef();
    this.state={
      searchForm : [],
      display:"table",
      recordNum:null
    }
  }
  switchView=(what,recordNum) =>{
    this.setState({display:what,recordNum:recordNum})
  }
  render(){
    return(
      <div>
        {this.state.display==="table" &&(
          <QuotesOverview
            url={this.url}
            switchView={this.switchView}
            signout={this.signout}
            ref={this.quoteTableRef}
          />
        )}
        {this.state.display==="detail" &&(
          <QuoteDetail
            recordNum={this.state.recordNum}
            url={this.url}
            switchView={this.switchView}
            signout={this.signout}
            createNotification = {this.createNotification}
            openAddressBook = {this.openAddressBook}
            addToAddressBook={this.addToAddressBook}
            ref = { this.quoteFormRef}
            createShipment = {this.createShipment}
            quoteTableRef = {this.quoteTableRef}
          />
        )}
      </div>
    )
  }
}
