import React from 'react';
export default function (props) {
  const updateState = props.updateState;
  const name = props.name;
  const sumfield = props.sumfield;
  React.useEffect(()=>{
    console.log("nastavuji",props.data)
  },[props.data])
  const addCostsRow = () =>{
    let costs = [...props.data];
    costs.push({text:"",val:0})
    updateState({target:{name : name,value : costs}});
  }
  const updateCost = (e,i)=>{
    let costs = [...props.data];
    let value = e.target.value;
    if(e.target.name==="val"){
      value=value.replace(/[^0-9\.]+/g,"");
    }
    costs[i][e.target.name]=value;
    let sum = 0;
    costs.forEach((cost)=>{
      sum+=parseFloat(cost.val);
    })
    console.log(sum);
    updateState({target:{name : name,value : costs}});
    updateState({target:{name : sumfield,value : sum}});
  }
  const removeCost = (index)=>{
    let costs = [...props.data];
    costs.splice(index, 1);
    let sum = 0;
    costs.forEach((cost)=>{
        sum+=parseFloat(cost.val);
    })
    updateState({target:{name : name,value : costs}});
    updateState({target:{name : sumfield,value : sum}});
  }
  return (
    <table style={{width:"100%"}}>
      <thead>
        <tr>
          <td width="10%">
            <button type="button" className="btn btn-primary" onClick={ addCostsRow }>
              <span className="fa fa-plus"></span>
            </button>
          </td>
          <td width="60%" className="small text-muted">Description</td>
          <td width="30%" className="small text-muted">Value</td>
        </tr>
      </thead>
      <tbody>
        {props.data.map((cost,i)=>{
          return(
            <tr key={i} >
              <td className="align-bottom" width="10%">
                <button className="btn btn-danger" type="button" onClick={()=>removeCost(i)}>
                  <i className="fa fa-minus"/>
                </button>
              </td>
              <td width="60%">
                  <input type="text" className="form-control" value={ cost.text } name="text" onChange= { (e)=> updateCost(e,i) } ></input>
              </td>
              <td width="30%">
                <div className="input-group">
                  <input type="text" className="form-control" value={ cost.val } name="val" onChange= { (e)=> updateCost(e,i) } ></input>
                  <div className="input-group-append">
                    <span className="input-group-text">€</span>
                  </div>
                </div>
              </td>
            </tr>
          )
        })
      }
      </tbody>
    </table>
  )
}
